import autenticacaoService from '@/service/autenticacao.servico'

const autenticar = {
  actions: {
    autenticar( { dispatch} , payload) {
      autenticacaoService(dispatch, payload)
    }
  }
};

export default autenticar