<template>
   <div id="frmListarCobrancas">
       <div class="column is-12 is-pulled-left is-paddingless">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#pagamentos-listar-cobranças"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-4 is-pulled-left">
                <span>*Inscrição Federal</span>
                <input class="input"
                    v-model="parametrosLista.inscricaoFederal">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>Status</span>
                <input class="input"
                    title="O texto escrito deve ser em caixa alta, ex.: ABERTO."
                    v-model="parametrosLista.status">
            </div>
            <div class="btnBuscarLista column is-2 is-pulled-right">
                <button id="btnBuscarLista" class="button is-full-width"
                    type="button"
                    @click="buscarLista(parametrosLista.inscricaoFederal, '', itensPagina )">
                    <i class="fas fa-search has-margin-right-10" aria-hidden="true"></i>Pesquisar</button>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-top-15">
            <div class="tblClasse-lista">
                <div class="txtTabelaVazia" v-if="!this.listaCobrancas.cobrancas">
                    Digite uma Inscrição Federal válida para visualizar os cobranças.
                </div>
                <table class="table is-striped is-paddingless">
                    <thead v-if="!!this.listaCobrancas.cobrancas">
                        <tr>
                            <th>Id Transação</th>
                            <th>Status</th>
                            <th>Data Criação</th>
                            <th>Pagamento Recebido</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(cobranca, i) in listaCobrancas.cobrancas" :key="i">
                            <td>{{ cobranca.id_transacao }}</td>
                            <td>{{cobranca.status}}</td>
                            <td>{{ formatarData(cobranca.data_criacao)}}</td>
                            <td>{{cobranca.pagamento_recebido}}</td>
                            <td> 
                                <img title="Detalhar" class="btnDetalharImg" :src="btnDetalharImg" @click="ExibirModalDetalhes(cobranca, true)"/>
                                <i title="Visualizar Estrutura" @click="ExibirModalDetalhes(cobranca, false)" class="far fa-eye fa-lg is-clickable" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-margin-top-15">
            <Paginacao 
                :pagina="listaCobrancas.paginacao.pagina" 
                :quantidadeDePaginas="listaCobrancas.paginacao.quantidade_paginas"
                :itensPorPagina="listaCobrancas.paginacao.itens_por_pagina"
                :totalItens="listaCobrancas.paginacao.quantidade_total_itens"
                :inscricaoFederal="parametrosLista.inscricaoFederal" 
                :buscarListaPagina="buscarLista"
                @retornarItensPagina="retornarItensPagina($event)"/>
        </div>
        <span class="is-pulled-left is-size-8 has-padding-left-30 has-padding-top-15">* Campos obrigatórios.</span>
        <Modal 
            v-if="exibirOcultarModal"
            @closeModal='closeModalEvent'
            :titulo='titulomodal'
            :largura="'950'"
            :altura="'445'">
            <frmDetalharItens slot="body" :cobrancaSelecionada='cobrancaSelecionada' v-if="frmDetalharItens"/>
            <div slot="body" class="scrollModal" v-if="!frmDetalharItens">
                <pre>{{cobrancaSelecionada}}</pre>
            </div>
        </Modal>
   </div>
</template>

<script>
import listarCobrancas from '@/model/listarCobrancas'
import frmDetalharItens from '@/views/pagamentos/frmDetalharItens.vue'
import Modal from '@/components/Modal.vue'
import {btnDetalharImg} from '../../assets/base64Img'
import Paginacao from '@/components/Paginacao.vue'
import { utils } from '@/helpers/utils'

export default {
    components: {
        Modal,
        frmDetalharItens,
        Paginacao
    },
    computed: {
        listaCobrancas() {
            return this.$store.state.ListarCobrancasModulo.lista
        },
        exibirOcultarModal(){
            return this.$store.state.ListarCobrancasModulo.exibirOcultarModal
        },
        btnDetalharImg() {
            return btnDetalharImg
        }
    },
    data() {
        return {
            titulomodal: '',
            cobrancaSelecionada: {},
            itensPagina: '',
            parametrosLista: new listarCobrancas(),
            frmDetalharItens: true
        }
    },
    methods: {
        formatarData(data) {
            return utils.FormatarData(data)
        },
        retornarItensPagina(itensPagina){
            this.itensPagina = itensPagina
        },
        ExibirModalDetalhes(dadosCobranca, Json){
            this.frmDetalharItens = Json
            this.titulomodal = `ID TRANSAÇÃO: ${dadosCobranca.id_transacao}`
            Json ? this.cobrancaSelecionada = dadosCobranca : this.cobrancaSelecionada = JSON.stringify(dadosCobranca, null, 2)
            this.$store.commit("alterarVisibilidadeModal", true)
        },
        buscarLista(inscricaoFederal, pagina, itensPorPagina) {
            let lista = this.parametrosLista

            lista.inscricaoFederal = inscricaoFederal
            lista.numeroPagina = pagina
            lista.itensPorPagina = itensPorPagina

            this.$store.dispatch("ArmazenarDadosStore",{
                dados: this.parametrosLista,
                componente: 'ListarCobrancas'
            })

            Object.keys(lista).forEach(key => lista[key] === "" ? delete lista[key] : {})
            this.$store.dispatch("consultarCobranca", lista)
        },
        closeModalEvent() {
            this.$store.commit("alterarVisibilidadeModal", false)
        },
        lerDadosLocalStorage() {
            if (localStorage.getItem(`MP-ListarCobrancas`)) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-ListarCobrancas`))
                this.parametrosLista = new listarCobrancas(localStorageDados)
            }
        }
    },
    created() {
        this.lerDadosLocalStorage()
    }
}
</script>