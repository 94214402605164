import { LojaService } from '@/service/loja.servico'

export const loja = {
    state: {
        lojasInscricaoFederal: {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}},
        listaLojasId: {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}},
        inscricaoFederal: '',
        exibirOcultarModal: false
    },

    actions: {
        resgistrarLoja({commit, dispatch}, payload) {
            LojaService.resgistrarLoja(commit, dispatch, payload)
        },
        buscarInscricaoFederal({commit, dispatch}, payload) {
            LojaService.buscarInscricaoFederal(commit, dispatch, payload)
        },
        buscarLojaId({commit, dispatch}, payload) {
            LojaService.buscarLojaId(commit, dispatch, payload)
        },
        deletarLoja({commit, dispatch}, payload) {
            LojaService.deletarLoja(commit, dispatch, payload)
        }
    },
 
    mutations: {
        buscarInscricaoFederal(state, payload) {
            state.lojasInscricaoFederal = payload
        },
        buscarLojaId(state, payload) {
            state.listaLojasId = payload
        },
        inscricaoFederal(state, payload) {
            state.inscricaoFederal = payload
        },
        alterarVisibilidadeModal(state, payload) {
            state.exibirOcultarModal = payload
        }
    }
}