import { render, staticRenderFns } from "./MensagemRetorno.vue?vue&type=template&id=1748c351&scoped=true&"
import script from "./MensagemRetorno.vue?vue&type=script&lang=js&"
export * from "./MensagemRetorno.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./MensagemRetorno.vue?vue&type=style&index=0&id=1748c351&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1748c351",
  null
  ,true
)

export default component.exports