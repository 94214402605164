<template>
    <TemplateNovoCaixa :dadosSelecao="dadosSelecao" :selecao="selecao" :funcaoEnviar = "enviar">
    </TemplateNovoCaixa>
</template>

<script>

import TemplateNovoCaixa from '@/components/frmNovoCaixaEditar'

export default {
    components:{
        TemplateNovoCaixa
    },
    props: {
        dadosSelecao: Object,
        selecao: Number
    },
    methods: {
        enviar(parametro){
            this.$store.dispatch("criarCaixa", parametro)
        }
    }
}
</script>

<style>

</style>