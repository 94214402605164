import { QrCodeService } from '@/service/qrCode/QrCode.servico'

const QrCodeModulo = {
    state() { 
        return {
            qrcodeObtido: ''
        }
    },
    mutations: {
        preencherQrcodeObtido(state,payload) {
          state.qrcodeObtido = payload
        }
    },
    actions: {
        enviarQrCode( { commit, dispatch }, payload) {
            QrCodeService.enviar(commit, dispatch, payload)
        },
        buscarQrcode( { commit, dispatch }, payload) {
            QrCodeService.buscar(commit, dispatch, payload)
        }
    }
}

export default QrCodeModulo