<template>
   <div id="frmGerarQrCode">
       <div class="column is-12 is-pulled-left is-paddingless">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#pagamentos-gerar-qrcode-único-por-pagamento"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-6 is-pulled-left">
                <span>*Descrição</span>
                <input class="input"
                    v-model="qrCode.descricao">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>*Inscrição Federal</span>
                <input class="input"
                    v-model="qrCode.inscricao_federal">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>ID Externo Caixa</span>
                <input class="input"
                    v-model="qrCode.id_externo_caixa">
            </div>
        </div>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-2 is-pulled-left">
                <span>ID Correlação</span>
                <input class="input"
                    v-model="qrCode.id_correlacao">
            </div>
            <div class="column is-2 is-pulled-left">
                <span>*Total Compra</span>
                <input class="input"
                    v-model="qrCode.total_compra">
            </div>
            <div class="column is-2 is-pulled-left">
                <span>Data Expiração</span>
                <input class="input"
                    v-model="qrCode.data_expiracao">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>Origem</span>
                <input class="input"
                    v-model="qrCode.origem">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>URL Notificação</span>
                <input class="input"
                    v-model="qrCode.url_notificacao">
            </div>
        </div>
        <div class="is-pulled-left">
            <hr class="is-marginless has-margin-top-5">
            <p class="has-padding-left-10">*Itens</p>
            <div class="column is-7 is-pulled-left is-marginless is-paddingless">
                <div class="column is-3 is-pulled-left">
                    <span>Código</span>
                    <input class="input"
                        v-model="itens.codigo_produto">
                </div>
                <div class="column is-9 is-pulled-left">
                    <span>*Nome</span>
                    <input class="input"
                        v-model="itens.nome">
                </div>
                <div class="column is-4 is-pulled-left">
                    <span>Categoria</span>
                    <input class="input"
                        v-model="itens.categoria">
                </div>
                <div class="column is-2 is-pulled-left">
                    <span>*UN</span>
                    <input title='Unidade de medida' class="input"
                        v-model="itens.unidade_medida">
                </div>
                <div class="column is-2 is-pulled-left">
                    <span>*Preço Unit.</span>
                    <input class="input"
                        v-model="itens.preco_unitario">
                </div>
                <div class="column is-2 is-pulled-left">
                    <span>*Quant.</span>
                    <input class="input"
                        v-model="itens.quantidade">
                </div>
                <div class="column is-2 is-pulled-left">
                    <span>*Valor Total</span>
                    <input class="input"
                        v-model="itens.valor_total">
                </div>
                <div class="column is-12 is-pulled-left">
                    <span>Descrição</span>
                    <textarea class="input" style="height: 57px"
                        name="descricao" 
                        cols="1" 
                        rows="2"
                        resize=none
                        v-model="itens.descricao">>
                    </textarea>
                </div>
                <div class="column is-12 is-pulled-left has-padding-top-15">
                    <button class="column is-3 button button-secundario is-pulled-right"
                        v-if="itens.indice !== undefined"
                        @click="adicionarProdutolista(itens)">
                        <i title='Confirmar alteração' class='fas fa-check fa-lg is-clickable has-text-success  has-padding-right-5' aria-hidden='true'/>
                        Salvar
                    </button>
                    <button class="column is-3 button button-secundario is-pulled-right"
                        v-if="itens.indice === undefined"
                        @click="adicionarProdutolista(itens)">
                        Adicionar
                    </button>
                    <button class="column is-3 button button-secundario is-pulled-right has-margin-right-25"
                        id="btn-cancelar-gerar-qrcode"
                        v-if="itens.indice !== undefined"
                        @click="cancelarEdicao(itens)">
                        <i title="Cancelar" class="fas fa-times fa-lg is-clickable has-text-danger has-padding-right-5" aria-hidden="true"/>
                        Cancelar
                    </button>
                    <span v-if="itens.indice !== undefined">Índice: {{itens.indice}}</span>
                </div>
            </div>
            <div class="column is-5 is-pulled-left has-padding-top-25">
                <div class="tblClasse-lista">
                    <table class="table is-striped is-paddingless">
                        <thead>
                            <tr>
                                <th>Índice</th>
                                <th>Nome</th>
                                <th>Quant.</th>
                                <th>Vl. Unit.</th>
                                <th>Vl. Total</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr v-for="(produto, i) in produtos" 
                                :key="i">
                                <td>{{ i }}</td>
                                <td>{{ produto.nome }}</td>
                                <td>{{produto.quantidade}}</td>
                                <td>{{produto.preco_unitario}}</td>
                                <td>{{produto.valor_total}}</td>
                                <td>
                                    <i class="far fa-edit fa-lg is-clickable has-padding-right-10" aria-hidden="true" title="Editar" @click="editarItemSelecionado(produto, i)"></i>
                                    <i class="far fa-trash-alt fa-lg is-clickable" aria-hidden="true" title="Excluir" @click="removerItens(i)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-top-15">
            <span class="is-pulled-left is-size-8 has-padding-left-15">* Campos obrigatórios.</span>
            <button id="btnEnviarQrCode" class="column is-2 button is-pulled-right" type="button"
                @click="enviarQrCode(produtos)">Enviar</button>
            <button class="column is-2 button button-secundario is-pulled-right has-margin-right-25"
                id="btnLimparTela"
                @click="limparTela()">Limpar</button>
        </div>
   </div>
</template>

<script>
import gerarQrCode from '@/model/gerarQrCode'
import qrCodeItens from '@/model/qrCodeItens'

export default {
    data() {
        return {
            itens: new qrCodeItens(),
            qrCode: new gerarQrCode(),
            produtos: []
        }
    },
    methods: {
        cancelarEdicao() {
            this.itens = new qrCodeItens()
        },
        limparTela(){
            this.itens = new qrCodeItens(),
            this.qrCode = new gerarQrCode(),
            this.produtos = []
        },
        editarItemSelecionado(item, indice){
            this.itens = new qrCodeItens(item)
            this.itens.indice = indice
        },
        adicionarProdutolista(itens) {
            if(itens.indice === undefined){
                this.produtos.push(itens)
                this.itens = new qrCodeItens()
            }else{
                this.produtos[itens.indice] = new qrCodeItens(itens)
                this.itens = new qrCodeItens()
            }
        },
        enviarQrCode(produtos) {
            this.qrCode.itens = produtos
            this.$store.dispatch("enviarQrCode",this.qrCode)
            this.$store.dispatch("ArmazenarDadosStore",{
                dados: this.qrCode,
                componente: 'GerarQrCode'
            })
        },
        removerItens(index){
            this.produtos.splice(index,1)
        },
        lerDadosLocalStorage() {
            if (localStorage.getItem(`MP-GerarQrCode`)) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-GerarQrCode`))
                this.qrCode = new gerarQrCode(localStorageDados)
                this.produtos = localStorageDados.itens
            }
        }
    },
    created() {
        this.lerDadosLocalStorage()
    }
}
</script>