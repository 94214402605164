export default class gerarQrCode {
    constructor(objeto) {
    
        this.origem = ""
        this.id_correlacao = ""
        this.id_externo_caixa = ""
        this.inscricao_federal = ""
        this.data_expiracao = ""
        this.descricao = ""
        this.url_notificacao = ""
        this.total_compra = ""
        this.itens = []

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}