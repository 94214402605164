import Vue from 'vue'
import Vuex from 'vuex'
import storeConfig from '@/store/storeConfig'
import QrCodeModulo  from '@/store/qrCode/QrCode.modulo'
import ListarCobrancasModulo from '@/store/qrCode/listarCobrancas.modulo'
import AutenticacaoModulo from '@/store/autenticacao.modulo'
import devolverPagamentoModulo from '@/store/qrCode/devolverPagamento.modulo'
import { loja } from '@/store/loja/loja.modulo'
import { Caixa } from '@/store/caixas/caixas.modulo'

Vue.use(Vuex)

const store = new Vuex.Store({
  ...storeConfig,
  modules: {
    QrCodeModulo,
    ListarCobrancasModulo,
    AutenticacaoModulo,
    devolverPagamentoModulo,
    loja,
    Caixa
  }
})

export default store