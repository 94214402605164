import axios from 'axios'
import config from 'config';
import { authHeader } from '../helpers'

export const CaixaService = {
    criar,
    buscar,
    deletar,
    editar,
    listar
}

function criar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload.body),
        headers: authHeader(),
        transformRequest: [function (data) {
            return data;
        }]
    }

    return axios(`${config.api}/mercadopago/caixa?inscricaoFederal=` + payload.inscricaoFederal,requestOptions)
    .then(function(response) {
        dispatch('montarMensagemSucesso', response)
        dispatch("ArmazenarDadosStore", {dados: payload, componente: 'NovoCaixa'})
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })

}

function buscar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    if(payload.url.numeroPagina === undefined){
        payload.url.numeroPagina= '1'
    }

    const requestOptions = {
        params:payload.url,
        method: 'get',
        headers: authHeader(),
        transformRequest: [function (data) {
            return data;
        }]
    }

    let urlIdCaixa = payload.caixa ? '/' + payload.caixa : ''
    
    return axios(`${config.api}/mercadopago/caixa${urlIdCaixa}`,requestOptions)
    .then(function(response) {
        commit('preencherListaCaixas',  response.data.caixas ? response.data : {
            paginacao: {pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0},
            caixas: [response.data]
        })
        dispatch("ArmazenarDadosStore", {dados: payload, componente: 'PesquisarExcluirCaixa'})
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        commit('preencherListaCaixas', {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}})

        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function listar (commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    if(payload.url.numeroPagina === undefined){
        payload.url.numeroPagina= '1'
    }

    const requestOptions = {
        params:payload.url,
        method: 'get',
        headers: authHeader(),
        transformRequest: [function (data) {
            return data;
        }]
    }

    return axios(`${config.api}/mercadopago/caixa?`,requestOptions)
    .then(function(response) {

        commit('preencherListaCaixas',  response.data.caixas ? response.data : {
            paginacao: {pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0},
            caixas: [response.data]
        })
        dispatch("ArmazenarDadosStore", {dados: payload, componente: 'PesquisarExcluirCaixa'})
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        commit('preencherListaCaixas', {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}})

        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function deletar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    const requestOptions = {
        method: 'delete',
        headers: authHeader(),
        transformRequest: [function (data) {
            return data;
        }]
    }

    return axios(`${config.api}/mercadopago/caixa/`+ payload.idCaixa +`?inscricaoFederal=` + payload.inscricaoFederal,requestOptions)
    .then(function(response) {
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function editar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        method: 'put',
        data: JSON.stringify(payload.body),
        headers: authHeader(),
        transformRequest: [function (data) {
            return data;
        }]
    }

    return axios(`${config.api}/mercadopago/caixa/${payload.idCaixa}?inscricaoFederal=${payload.inscricaoFederal}`,requestOptions)
    .then(function(response) {
        dispatch('montarMensagemSucesso', response)
        dispatch("ArmazenarDadosStore", {dados: payload, componente: 'EditarCaixa'})
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}