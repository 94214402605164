<template>
   <div id="frmNovaLojaEditar">
       <div class="column is-12 is-pulled-left is-paddingless has-padding-top-15">
            <span v-if="selecao === 3" class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-lojas-registrar-loja"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
            <span v-if="selecao === 4" class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-lojas-editar"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-3 is-pulled-left"
                v-if="selecao === 4">
                <span>*ID Loja</span>
                <input :disabled="selecao === 4 && dadosSelecao.id_loja" class="input nome" v-model="dadosLoja.id_loja">
            </div>
            <div class="column is-pulled-left"
                :class="[selecao === 4 ? 'is-5': 'is-6']">
                <span>*Nome</span>
                <input class="input nome" v-model="dadosLoja.nome_loja">
            </div>
            <div class="column is-pulled-left"
                :class="[selecao === 4 ? 'is-2': 'is-3']">
                <span>*CNPJ/CPF</span>
                <input class="input inscricaoFederal" v-model="inscricaoFederal">
            </div>
            <div class="column is-pulled-left"
                :class="[selecao === 4 ? 'is-2': 'is-3']">
                <span>ID Externo</span>
                <input class="input idExterno" v-model="idExterno">
            </div>
        </div>
        <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-top-10"/>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-6 is-pulled-left is-marginless is-paddingless">
                <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                    <span class="has-padding-left-10 is-size-6">Localização</span>
                    <div class="column is-12 is-pulled-left">
                        <span>*Rua</span>
                        <input class="input rua" v-model="dadosLoja.localizacao.rua">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                    <div class="column is-3 is-pulled-left">
                        <span>*Número</span>
                        <input class="input numero" v-model="dadosLoja.localizacao.numero">
                    </div>
                    <div class="column is-3 is-pulled-left">
                        <span>*CEP</span>
                        <input class="input cep" v-model="dadosLoja.localizacao.cep">
                    </div>
                    
                    <div class="column is-6 is-pulled-left">
                        <span>*Cidade</span>
                        <input class="input cidade" v-model="dadosLoja.localizacao.cidade">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                    <div class="column is-6 select is-pulled-left">
                        <span>*Estado</span>
                        <select class="input" v-model="dadosLoja.localizacao.estado">
                            <option value=""></option>
                            <option v-for="(estado, i) in estado" :key="i" :value="estado.nome">
                                {{estado.nome}}</option>
                        </select>
                    </div>
                    <div class="column is-3 is-pulled-left">
                        <span>*Latitude</span>
                        <input class="input latitude" v-model="dadosLoja.localizacao.latitude">
                    </div>
                    <div class="column is-3 is-pulled-left">
                        <span>*Longitude</span>
                        <input class="input longitude" v-model="dadosLoja.localizacao.longitude">
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                    <div class="column is-12 is-pulled-left">
                        <span>Ponto de Referência</span>
                        <input class="input ponto_referencia" v-model="dadosLoja.localizacao.ponto_referencia">
                    </div>
                </div>
                <div class="ajuda column is-12">
                    <span class="is-pulled-left is-size-8">* Campos obrigatórios.</span>
                    <span class="is-pulled-left is-size-8" v-if="selecao === 4 && dadosSelecao.id_loja">- Campos não preenchidos: Serão enviados como vazio.</span>
                    <span class="is-pulled-left is-size-8" v-if="selecao === 4 && !dadosSelecao.id_loja">- Campos não preenchidos: Não serão enviados, mantendo os valores já cadastrados.</span>
                </div>
            </div>
            <div class="column is-6 is-pulled-left is-marginless is-paddingless">
                <span class="has-padding-left-10 is-size-6">Dia/Horário de Funcionamento</span>
                <div class="column is-12 is-pulled-left is-paddingless has-padding-bottom-15">
                    <div class="column is-3 select is-pulled-left">
                        <span>*Dia</span>
                        <select class="input" v-model="dia">
                            <option v-for="(dia, i) in diaSemana" :key="i" :value="dia.valor">
                                {{dia.nome}}</option>
                        </select>
                    </div>
                    <div class="column is-3 is-pulled-left">
                        <span>*Abertura</span>
                        <input class="input" v-model="funcionamento.abertura"
                            type="time" min="00:00" max="23:59" required>
                    </div>
                    <div class="column is-3 is-pulled-left">
                        <span>*Fechamento</span>
                        <input class="input" v-model="funcionamento.fechamento"
                            type="time" min="00:00" max="23:59" required>
                    </div>
                    <div v-if="funcionamento.indice !== undefined" class="column is-3 is-paddingless is-pulled-left">
                        <div class="ml-3">
                            <span>Índice: {{funcionamento.indice}}</span>
                        </div>
                        <div class="column is-6 is-pulled-left">
                            <button @click="listaFuncionamento(dia, funcionamento)" class="button-secundario button is-full-width">
                            <i title="Confirmar alteração" class="btnAdd fas fa-check fa-lg is-clickable has-text-success" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="column is-6 is-pulled-left">
                            <button @click="cancelarEdicaoFuncionamento()" class="button-secundario button is-full-width">
                            <i title="Cancelar" class="btnCancelarEdicao fas fa-times fa-lg is-clickable has-text-danger" aria-hidden="true"></i>
                            </button>
                        </div>
                        </div>
                        
                    <div v-if="funcionamento.indice === undefined" id="btnAdicionarItemLista" class="column is-3 is-pulled-left">
                        <button
                            class="button-secundario button is-fullwidth" 
                            @click="listaFuncionamento(dia, funcionamento)">Adicionar</button>
                    </div>
                </div>
                <div class="column is-12 is-pulled-left is-paddingless">
                    <div class="tblClasse-lista">
                        <table class="table is-striped is-paddingless">
                            <thead>
                                <tr>
                                    <th>Índice</th>
                                    <th>*Dia</th>
                                    <th>*Abertura</th>
                                    <th>*Fechamento</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody> 
                                <tr v-for="(funcionamento, i) in arrayFuncionamento" :key="i">
                                        <td>{{ i }}</td>
                                        <td class="has-padding-top-5">
                                            <span>{{funcionamento.nome}}</span>
                                        </td>
                                        <td class="has-padding-top-5">
                                            <span>{{funcionamento.abertura}}</span>
                                        </td>
                                        <td class="has-padding-top-5">
                                            <span>{{funcionamento.fechamento}}</span>
                                        </td>
                                        <td>
                                            <i title="Editar" @click="editarFuncionamento(funcionamento, i)" class="btnEditarFuncionamento far fa-edit fa-lg is-clickable has-padding-right-10" aria-hidden="true"></i>
                                            <i title="Excluir" @click="excluirFuncionamento(i)" class="btnExcluirFuncionamento far fa-trash-alt fa-lg is-clickable" aria-hidden="true"></i>
                                        </td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-padding-top-15">
            <button :disabled="funcionamento.indice !== undefined" type="submit" class="btnEnviarDados column is-2 button is-pulled-right" @click="enviarParametros()">Enviar</button>
            <button class="btnLimpar column is-2 button button-secundario is-pulled-right has-margin-right-25" @click="limpar()">Limpar</button>
        </div>
    </div>
</template>

<script>
import NovaLoja from '@/model/Lojas/novaLoja'

export default {
    props: {
        dadosSelecao: Object,
        selecao: Number,
        funcaoEnviar:  Function
    },

    data() {
        return {
            inscricaoFederal:'',
            dia:'',
            dadosLoja: new NovaLoja(),
            diaSemana: [{nome:'Segunda', valor:'segunda'},{nome:'Terça', valor:'terca'},{nome:'Quarta', valor:'quarta'},
                {nome:'Quinta', valor:'quinta'},{nome:'Sexta', valor:'sexta'},{nome:'Sábado', valor:'sabado'},{nome:'Domingo', valor:'domingo'}],
            estado:[{ nome:'Acre'},{ nome:'Alagoas'},{ nome:'Amapá'},{ nome:'Amazonas'},
                { nome:'Bahia'},{ nome:'Ceará'},{ nome:'Distrito Federal'},{ nome:'Espirito Santo'},
                { nome:'Goiás'},{ nome:'Maranhão'},{ nome:'Mato Grosso do Sul'},{ nome:'Mato Grosso'},
                { nome:'Minas Gerais'},{ nome:'Pará'},{ nome:'Paraíba'},{ nome:'Paraná'},
                { nome:'Pernambuco'},{ nome:'Piauí'},{ nome:'Rio de Janeiro'},{ nome:'Rio Grande do Norte'},
                { nome:'Rio Grande do Sul'},{ nome:'Roraima'},{ nome:'Santa Catarina'},{ nome:'São Paulo'},
                { nome:'Sergipe'},{ nome:'Tocantins'}],
            funcionamento: {
                abertura: '', fechamento: '',
            },
            obj:{},
            arrayFuncionamento:[],
            dadosFunc: {},
            parametros: {},
            idExterno:'',
        }
    },
    computed: {
        InscricaoFederal() {
            return this.$store.state.loja.inscricaoFederal
        }
    },
    methods: {
        cancelarEdicaoFuncionamento(){
            this.funcionamento = {}
            this.dia = ''
        },

        limpar(){
            this.dadosLoja = new NovaLoja()
            this.inscricaoFederal = ''
            this.arrayFuncionamento = []
            this.idExterno = ''
        },

        removeAtributosVazios() {
            Object.keys(this.dadosLoja).forEach(key => this.dadosLoja[key] === "" ? delete this.dadosLoja[key] : {})
            Object.keys(this.dadosLoja.localizacao).forEach(key => this.dadosLoja.localizacao[key] === "" ? delete this.dadosLoja.localizacao[key] : {})
            
            if(this.dadosLoja.funcionamento === undefined || !Object.keys(this.dadosLoja.funcionamento).length){
                delete this.dadosLoja.funcionamento
            }
        },

        enviarParametros(){
            if(this.selecao === 4 && !this.dadosSelecao.id_loja){
                this.removeAtributosVazios()
            }

            if(((this.idExterno === '' && !this.dadosSelecao.id_loja) || this.idExterno === this.dadosLoja.id_externo_loja) && this.selecao === 4){
                delete this.dadosLoja.id_externo_loja
             }else{
                this.dadosLoja.id_externo_loja = this.idExterno
            }

            this.parametros = {dadosLoja: this.dadosLoja, inscricaoFederal: this.inscricaoFederal, idExterno: this.idExterno}
            this.funcaoEnviar(this.parametros)
            this.dadosLoja.id_externo_loja = this.idExterno
        },

        preparaEnvioFuncionamento(func){
            for(let funcionamentoPut of func){

                let Dia = funcionamentoPut.nome.toLowerCase()
                this.funcionamento = {abertura:funcionamentoPut.abertura, fechamento:funcionamentoPut.fechamento}
                
                this.preencherListaFuncionamento(Dia) 
            }
        },
        
        excluirFuncionamento(i){
            this.dadosFunc = {}
            
            this.arrayFuncionamento.splice(i,1)

            this.preparaEnvioFuncionamento(this.arrayFuncionamento)           
            
            if(this.arrayFuncionamento.length === 0 ){
                this.dadosLoja.funcionamento = {}
            }
        },

        editarFuncionamento(func, indice){
            this.dia = this.primeiraLetraMinuscula(func.nome)
            this.funcionamento.abertura = func.abertura
            this.funcionamento.fechamento = func.fechamento
            this.funcionamento.indice = indice
        },

        listaFuncionamento(dia, func){
            if(dia){
                if(func.indice === undefined){
                    this.obj = {...this.funcionamento, nome:this.primeiraLetraMaiuscula(dia)}
                    this.arrayFuncionamento.push(this.obj)
                    this.preencherListaFuncionamento(dia)
                }else{
                    this.dadosFunc = {}
                    this.obj = {...this.funcionamento, nome:this.primeiraLetraMaiuscula(dia)}
                    this.arrayFuncionamento.splice(func.indice, 1, this.obj)
                    
                    this.preparaEnvioFuncionamento(this.arrayFuncionamento)
                }
            }         

            this.funcionamento = {}
            this.dia = ''
            
        },

        listarFuncionamentoEdicao(){
            this.dadosFunc = this.dadosLoja.funcionamento
            
            for(let nomes of this.diaSemana){
                let nome = nomes.valor
                
                if(Object.getOwnPropertyNames(this.dadosFunc).includes(nome)) {
                    for(let horario of this.dadosLoja.funcionamento[nome]){
                        this.obj = {...horario, nome: this.primeiraLetraMaiuscula(nome)}
                        this.arrayFuncionamento.push(this.obj)
                    }
                }
            }
        },

        preencherListaFuncionamento(dia){
            if(Object.getOwnPropertyNames(this.dadosFunc).includes(dia)) {
                this.dadosFunc[dia].push(this.funcionamento)
            }else{
                this.dadosFunc = {
                    ...this.dadosFunc,
                    [dia]:[this.funcionamento]
                }
            }
            this.dadosLoja.funcionamento = this.dadosFunc
        },

        primeiraLetraMaiuscula(dia) {
            return dia[0].toUpperCase() + dia.slice(1);
        },

        primeiraLetraMinuscula(dia) {
            return dia[0].toLowerCase() + dia.slice(1);
        },

        valorDeEntradaEdicao(){
            if(this.selecao === 4 && this.dadosSelecao.funcionamento){
                this.dadosLoja = {...this.dadosSelecao}
                this.dadosLoja.localizacao = {...this.dadosSelecao.localizacao}
                this.idExterno = this.dadosLoja.id_externo_loja
                this.inscricaoFederal = this.InscricaoFederal
                this.listarFuncionamentoEdicao()
            }
        },

        lerDadosLocalStoragePost() {
            if (localStorage.getItem(`MP-NovaLoja`) && this.selecao === 3) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-NovaLoja`))
                this.dadosLoja = new NovaLoja(localStorageDados.body)
                this.inscricaoFederal = localStorageDados.url
                this.idExterno = localStorageDados.body.id_externo_loja
                this.listarFuncionamentoEdicao()
            }
        },

        lerDadosLocalStoragePut() {
            if (localStorage.getItem(`MP-EditarLoja`) && this.selecao === 4 && !this.dadosSelecao.id_loja) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-EditarLoja`))
                this.dadosLoja = new NovaLoja(localStorageDados.body)
                this.inscricaoFederal = localStorageDados.url
                this.idExterno = localStorageDados.idExterno
                this.listarFuncionamentoEdicao()
            }
        }
    },

    created() {
       this.valorDeEntradaEdicao()
       this.lerDadosLocalStoragePost()
       this.lerDadosLocalStoragePut()
    }
}
</script>

<style lang="scss" scoped>
.tblClasse-lista{
    width: 95.6%;
    margin-top: 10px;
}

.ajuda{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

#btnLimpar{
    margin-right: 10px;
}

#btnAdicionarItemLista{
    padding-top: 22.5px;
    button {
        margin: auto;
    }
}
</style>