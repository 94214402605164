<template>
    <div id="cpntPaginacao" class="column is-12 is-pulled-left">
        <div class="">
            <span class="column is-1 is-pulled-left">Página: {{pagina}} </span>
            <span class="column is-2 is-pulled-left">Total páginas:  {{quantidadeDePaginas}} </span>
            <span class="column is-2 is-pulled-left">Total itens:  {{totalItens}} </span>
        </div>
        <div class="paginacao">
            <span>* Itens por página: </span>
            <div class="select">
                <select :disabled="totalItens < 1" 
                        v-model="qtItensPorPagina" 
                        @change="buscarListaPagina(inscricaoFederal, 1, qtItensPorPagina)"
                        :class="{'is-active': totalItens < 1}" >   
                <option v-for="(Itens,i) in listaItensPorPagina"
                    :key="i" 
                    :value="Itens"
                    :selected="retornarItensPagina(qtItensPorPagina)">
                    {{Itens}}</option>
                </select>
            </div>
            <span class="btnPrimeiraPagina setasPaginacao column is-pulled-left" 
                    :class="{'is-active': pagina === 1 || pagina === 0}" 
                    @click="primeiraUltimaPagina(1)">&#171;</span>
            <span class="btnAnterior setasPaginacao column  is-pulled-left" 
                    :class="{'is-active': pagina === 1 || pagina === 0}" 
                    @click="anterior">&#60;</span>
            <span class="NumeroPagina column  is-pulled-left">{{pagina}}</span>
            <span class="btnProximo setasPaginacao column  is-pulled-left" 
                    :class="{'is-active': pagina === quantidadeDePaginas || pagina === 0}" 
                    @click="proximo">&#62;</span>
            <span class="btnUltimaPagina setasPaginacao column  is-pulled-left" 
                    :class="{'is-active': pagina === quantidadeDePaginas}" 
                    @click="primeiraUltimaPagina(quantidadeDePaginas)">&#187;</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        pagina: Number,
        quantidadeDePaginas: Number,
        itensPorPagina: Number,
        totalItens: Number,
        inscricaoFederal: String,
        buscarListaPagina: Function
    },
    data() {
        return {
            Pagina: 1,
            novaPagina: 1,
            qtItensPorPagina: this.itensPorPagina,
            listaItensPorPagina: [
                '5', '10', '15', '20', '50', '100'
            ]
        }
    },
    
    methods: {
        proximo(){
            this.Pagina = this.pagina + 1

            if(this.Pagina > this.quantidadeDePaginas){
                this.Pagina--
            }

            this.buscarListaPagina(this.inscricaoFederal, this.Pagina, this.qtItensPorPagina)
        },

        anterior(){
            this.Pagina = this.pagina - 1

            if(this.Pagina < 1){
                this.Pagina++
            } 

            this.buscarListaPagina(this.inscricaoFederal, this.Pagina, this.qtItensPorPagina)
        },
        primeiraUltimaPagina(pagina){
            this.Pagina = pagina
            this.buscarListaPagina(this.inscricaoFederal, this.Pagina, this.qtItensPorPagina)
        },
        retornarItensPagina() {
            this.qtItensPorPagina = this.qtItensPorPagina ? this.qtItensPorPagina : this.itensPorPagina
            this.$emit("retornarItensPagina", this.qtItensPorPagina)
        }
    }
}
</script>

<style lang='scss' scoped>

.paginacao{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    .column {
        flex-grow: 0;
    }
    .is-active{
        pointer-events: none;
        color: #b9b5b5;
    }
    .setasPaginacao{
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: flex-end !important;
        font-size: 17px;
        cursor: pointer;
        &:hover{
            color: #817a7a;
            transform: scale(1.1);
        }
    }
    .NumeroPagina{
        font-size: 15px;
    }
}

// @media screen and (max-height: 744px){
//     select {
//         margin-top: -3px;
//     }
// }

.select {
    select {
        height: 24px !important;
        width: 60px;
        margin-left: 6px;
        font-size: 12px;
        line-height: 1;
        &:focus {
            box-shadow: none;
            border-color: #dbdbdb;
        }
    }
    &:not(.is-multiple) {
        height: 1px !important;
    }
    &:not(.is-multiple):not(.is-loading)::after {
        margin-top: 5px !important;
        right: 8px;
    }
}

</style>