<template>
   <div id="frmPesquisarExcluirCaixa">
       <div class="column is-12 is-pulled-left is-paddingless has-padding-top-10">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-caixas-caixa-listar"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left">
            <div class="column is-12  is-marginless is-paddingless has-margin-bottom-10 is-flex is-justify-content-space-between">
                <div class="column is-4 pl-0">
                    <span>*Inscrição Federal</span>
                    <input
                        class="input"
                        type="text"
                        v-model="inscricaoFederal"/>
                </div>
                <div class="column is-4 is-pulled-left">
                    <span>ID Caixa</span>
                    <span class="is-pulled-right">
                            <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-caixas-buscar"
                                target="_blank">
                                <i class="fas fa-question-circle fa-ms" aria-hidden="true" title="Obter ajuda da documentação"></i>
                            </a>
                    </span>
                    <input
                        :disabled="camposListarPreenchidos"
                        class="input"
                        type="text"
                        v-model="idCaixa"/>
                </div>
                <div class="column is-4 pr-0">
                    <span>ID Externo Caixa</span>
                    <input
                        :disabled="!!idCaixa"
                        class="input"
                        type="text"
                        v-model="payloadListarCaixas.idExternoCaixa"/>
                </div>
            </div>
            <div class="column is-12  is-marginless is-paddingless has-margin-bottom-10 is-flex is-justify-content-space-between">
                <div class="column is-4 pl-0">
                    <span>ID Loja</span>
                    <input
                        :disabled="!!idCaixa"
                        class="input"
                        type="text"
                        v-model="payloadListarCaixas.idLoja"/>
                </div>
                <div class="column is-4">
                    <span>Categoria</span>
                    <input
                        :disabled="!!idCaixa"
                        class="input"
                        type="text"
                        v-model="payloadListarCaixas.categoria"/>
                </div>
                <div class="column is-4 pr-0 is-pulled-right has-padding-top-20">
                        <button type="button" id="btnPesquisa" class="column button is-half is-pulled-right btnBuscarCaixaId" @click="buscarCaixa(inscricaoFederal, 1, itensPagina)">
                        <i class="fas fa-search has-margin-right-10" aria-hidden="true"></i>Pesquisar</button>
                </div>
            </div>
        </div>

        <div class="column is-12 is-pulled-left has-padding-top-15">
            <div class="tblClasse-lista">
                <div class="txtTabelaVaziaCaixa" v-if="!this.listaCaixas.caixas">
                    Digite uma Inscrição Federal válida para visualizar os caixas cadastrados.
                </div>
                <table class="table is-striped is-paddingless">
                    <thead v-if="this.listaCaixas.caixas">
                        <tr>
                            <th>Id Caixa</th>
                            <th>Nome</th>
                            <th>Id Externo</th>
                            <th>Data Criação</th>
                            <th>Valor Fixo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.listaCaixas.caixas">
                        <tr v-for="(caixa, i) in listaCaixas.caixas" :key="i">
                            <td>{{ caixa.id_caixa }}</td>
                            <td>{{ caixa.nome_caixa }}</td>
                            <td>{{ caixa.id_externo_caixa }}</td>
                            <td>{{ formatarData(caixa.data_criacao)}}</td>
                            <td>{{ caixa.valor_fixo }}</td>
                            <td>
                                <div class="excluir column is-pulled-right">
                                    <i title="Visualizar Estrutura" @click="visualizarCaixa(caixa)" class="is-clickable far fa-eye has-padding-right-10 is-size-6 btnEditarCaixa btnVisualizarCaixa" aria-hidden="true"></i>
                                    <i title="Editar" @click="editarCaixa(caixa)" class="is-clickable far fa-edit has-padding-right-10 is-size-6 btnEditarCaixa" aria-hidden="true"></i>
                                    <i title="Excluir" @click="deletarCaixa(caixa)" class="is-clickable far fa-trash-alt has-padding-right-10 is-size-6 btnDeletarCaixa" aria-hidden="true"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-margin-top-15">
            <Paginacao
                :pagina="listaCaixas.paginacao.pagina"
                :quantidadeDePaginas="listaCaixas.paginacao.quantidade_paginas"
                :itensPorPagina="listaCaixas.paginacao.itens_por_pagina"
                :totalItens="listaCaixas.paginacao.quantidade_total_itens"
                :inscricaoFederal="inscricaoFederal"
                :buscarListaPagina="buscarCaixa"
                @retornarItensPagina="atualizarPaginacao($event)"/>
        </div>
        <span class="is-pulled-left is-size-8 has-padding-left-30 has-padding-top-15">* Campos obrigatórios.</span>
        <modal-confirmacao
            :class="exibirModalConfirmacao"
            :mensagem="mensagem"
            :titulo="titulomodal"
            :url='url'
            @modalFechado="fecharModalConfirmarExclusao">
        </modal-confirmacao>
        <Modal
        v-if="exibirOcultarModal"
        @closeModal='closeModalEvent'
        :titulo='titulomodal'
        :largura="'950'"
        :altura="'445'"
        >
            <div slot="body" class="slotModal">
                <div class="column is-6 qr_code">
                    <div>
                        <span>QrCode do caixa:</span>
                    </div>
                    <img v-if="qrCodeSelecionado"
                    :class="{qrCodeImg : zoom_qr_code}"
                    title="Clique na imagem para aplicar zoom."
                    :src="`${qrCodeSelecionado}`"
                    @click="qrCodeZoom()">
                </div>
                <div>
                    <span>Retorno da requisição:</span>
                </div>
                    <pre>{{ caixaSelecionado }}</pre>
            </div>
        </Modal>
   </div>
</template>

<script>

import ModalConfirmacao from '../../components/ModalConfirmacao.vue'
import Modal from '../../components/Modal.vue'
import Paginacao from '@/components/Paginacao.vue'
import { utils } from '@/helpers/utils'

export default {
  components: { ModalConfirmacao, Modal, Paginacao},
  props: [ 'editarSelecao', 'selecao' ],
    data () {
        return {
            itensPagina: '',
            qrCodeSelecionado: '',
            zoom_qr_code: false,
            titulomodal: '',
            payloadExclusao: {},
            payloadListarCaixas: {
                idExternoCaixa:'',
                idLoja:'',
                categoria:''
            },
            idCaixa: '',
            inscricaoFederal: '',
            caixaSelecionado: '',
            exibirModalConfirmacao: '',
            confirmarExclusao: false,
            mensagem: "Tem certeza que deseja apagar esse caixa?",
            url: 'https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-caixas-excluir'
        }
    },
    created(){
        this.lerDadosLocalStorage()
    },
    computed: {
        listaCaixas() {
            return this.$store.state.Caixa.listaCaixas
        },
        exibirOcultarModal(){
            return this.$store.state.Caixa.exibirOcultarModal
        },
        camposListarPreenchidos(){
            Object.keys(this.payloadListarCaixas).forEach(key => this.payloadListarCaixas[key] === "" ? delete this.payloadListarCaixas[key] : {})

            return (Object.keys(this.payloadListarCaixas).length ? true : false)
        },
    },
    methods: {
        lerDadosLocalStorage(){
            if (localStorage.getItem(`MP-PesquisarExcluirCaixa`) && this.selecao === 8) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-PesquisarExcluirCaixa`))
                this.inscricaoFederal = localStorageDados.url.inscricaoFederal
                this.idCaixa = localStorageDados.caixa ? localStorageDados.caixa : ""
                this.payloadListarCaixas.idExternoCaixa = localStorageDados.url.idExternoCaixa ? localStorageDados.url.idExternoCaixa : ""
                this.payloadListarCaixas.idLoja = localStorageDados.url.idLoja ? localStorageDados.url.idLoja : ""
                this.payloadListarCaixas.categoria = localStorageDados.url.categoria ? localStorageDados.url.categoria : ""
            }
        },
        atualizarPaginacao(qtde){
            this.itensPagina = qtde
        },
        visualizarCaixa(caixa){
            this.titulomodal = 'ID do caixa: ' + caixa.id_caixa
            this.qrCodeSelecionado = caixa.qrcode_info.url_imagem
            this.caixaSelecionado = JSON.stringify(caixa, null, 2)
            this.$store.commit('abrirFecharModal', true)
        },
        closeModalEvent(){
            this.$store.commit('abrirFecharModal', false)
        },
        qrCodeZoom () {
            this.zoom_qr_code = this.zoom_qr_code ? false : true
        },
        formatarData(data){
           return utils.FormatarData(data)
        },
        editarCaixa(caixa){
            let payload = {
                inscricaoFederal : this.inscricaoFederal,
                caixa : caixa
            }
            this.editarSelecao('editar-caixa', 7 , payload)
        },
        buscarCaixa(inscricaoFederal, pagina, itensPorPagina){
            let payload = {
                caixa: this.idCaixa,
                url: {
                    numeroPagina : pagina,
                    inscricaoFederal : inscricaoFederal,
                    itensPorPagina : itensPorPagina,
                    ...this.payloadListarCaixas
                }
            }

            if(this.camposListarPreenchidos){
                this.$store.dispatch("listarCaixas", payload)
            } else {
                this.$store.dispatch("buscarCaixa", payload)
            }
        },
        deletarCaixa(caixa){
            this.titulomodal = 'ID do caixa: ' + caixa.id_caixa
            this.mensagem = `Tem certeza que deseja apagar o caixa <b><strong>${caixa.nome_caixa}</strong></b>?`
            this.payloadExclusao.inscricaoFederal = this.inscricaoFederal
            this.payloadExclusao.idCaixa = caixa.id_caixa

            this.exibirModalConfirmacao = 'is-active';
        },
        fecharModalConfirmarExclusao(confirmacao){
                this.exibirModalConfirmacao = ''

                if(confirmacao) {
                    this.$store.dispatch("deletarCaixa", this.payloadExclusao)
                     setTimeout(() => {
                         this.recarregarGridAposDelecao()
                     }, 2000)
                }
        },
        recarregarGridAposDelecao(){
            if(this.idCaixa) {
                this.$store.commit('preencherListaCaixas', {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}})
            } else {
                let numeroPagina = this.listaCaixas.caixas.length !== 1 ? this.listaCaixas.paginacao.pagina : (this.listaCaixas.paginacao.pagina - 1)
                this.buscarCaixa(this.inscricaoFederal, numeroPagina, this.itensPagina)
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.slotModal{
  height: 23.4rem !important;
    overflow: auto;
    height: calc(100vh - 300px);
    border: 1px #dbdbdb solid;
    border-radius: 3px ;
}

.txtTabelaVaziaCaixa{
    height: calc(100vh - 425px);
    text-align: center;
    padding-top: 110px;
}

pre{
    overflow: visible;
    width:fit-content;
}

.qr_code {
    img{
        height: 100px;
        cursor: zoom-in;
    }
}

.qrCodeImg {
    height: 300px !important;
    cursor: zoom-out !important;
}

#btnPesquisa{
    height: 100%;
}

.table-scroll{
    margin: 15px 0 15px 0;
    width: 100%;
}
.tableFix{
    width: 100%;
}
td{
    width: 33rem;
}
.column{
   display: table-row;
}

</style>