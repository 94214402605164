export default class NovaLoja {

    constructor(objeto) {
        this.nome_loja = ''
        this.id_externo_loja = ''
        this.funcionamento = {}
        this.localizacao = {
            cep: '',
            rua: '',
            numero: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            ponto_referencia: ''
        }
        Object.assign(this, objeto);
    }
}