<template>
    <div id="frmDetalharItens">
        <span class="column is-2 is-pulled-left has-text-weight-semibold">Recebimentos</span>
        <hr class="column is-10 is-pulled-left is-marginless has-margin-top-10">
        <div class="column is-12 is-pulled-left has-padding-bottom-10">
            <div class="tblClasse-lista">
                <table class="table is-striped is-paddingless">
                    <thead>
                        <tr>
                            <th>Total Pago</th>
                            <th>Data Aprovação</th>
                            <th>Custo Envio</th>
                            <th>Total Devolvido</th>
                            <th>Total Cobrado</th>
                            <th>Moeda</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(recebimento, i) in cobrancaSelecionada.recebimentos" 
                            :key="i">
                            <td>{{recebimento.total_pago | toCurrency}}</td>
                            <td>{{formatarData(recebimento.data_aprovacao)}}</td>
                            <td>{{recebimento.custo_envio | toCurrency}}</td>
                            <td>{{recebimento.total_devolvido | toCurrency}}</td>
                            <td>{{recebimento.total_cobrado | toCurrency}}</td>
                            <td>{{recebimento.moeda}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <span class="column is-1 is-pulled-left has-text-weight-semibold">Itens</span>
        <hr class="column is-11 is-pulled-left is-marginless has-margin-top-10">
        <div class="column is-12 is-pulled-left">
            <div class="tblClasse-lista tblDetalharItens">
                <table class="table is-striped is-paddingless">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Quantidade</th>
                            <th>Preço Unitário</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="(iten, i) in cobrancaSelecionada.itens" 
                            :key="i">
                            <td>{{iten.nome }}</td>
                            <td>{{iten.descricao}}</td>
                            <td>{{iten.quantidade}}</td>
                            <td>{{iten.preco_unitario | toCurrency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { utils } from '@/helpers/utils'

export default {
    props: {
        cobrancaSelecionada: Object
    },
    methods:{
        formatarData(data) {
            return utils.FormatarData(data)
        },
    }
}
</script>