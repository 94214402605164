<template>
    <div id="frmPesquisarExcluir">
        <div class="column is-12 is-pulled-left is-paddingless has-padding-top-10">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-lojas-listar"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-12 is-pulled-left pr-0">
            <div class="column is-12  is-marginless is-paddingless has-margin-bottom-10 is-flex is-justify-content-space-between">
                <div class="column is-4 is-paddingless">
                    <span>*Inscrição Federal</span>
                    <input 
                        class="input" 
                        type="text" 
                        v-model="inscricaoFederal"/>
                </div>
                <div class="column is-4 is-pulled-left">
                    <span>ID Loja</span>
                    <span class="is-pulled-right pr-1">
                        <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-lojas-buscar"
                            target="_blank">
                            <i class="fas fa-question-circle fa-ms" aria-hidden="true" title="Obter ajuda da documentação"></i>
                        </a>
                    </span>
                    <input 
                        class="input" 
                        type="text" 
                        v-model="lojasId"/>
                </div>
                <div class="column is-2 has-padding-top-20 is-pulled-right">
                    <button type="button" id="btnPesquisa" class="button is-full-width" @click="lojasId ? buscarLojaId() : buscarInscricaoFederal(inscricaoFederal, '', itensPagina)">
                    <i  class="fas fa-search has-margin-right-10" aria-hidden="true"></i>Pesquisar</button>
                </div>
            </div>
            
        </div>
        <div class="column is-12 is-pulled-left has-padding-top-15">
            <div class="tblClasse-lista">
                <div class="txtTabelaVazia" v-if="listaLoja.lojas === undefined && listaLoja.id_loja === undefined">
                    Digite uma Inscrição Federal válida para vizualizar as lojas cadastradas.
                </div>
                <table class="table is-striped is-paddingless">
                   
                    <thead v-if="listaLoja.lojas !== undefined || listaLoja.id_loja !== undefined">
                        <tr>
                            <th>ID Loja</th>
                            <th>Nome Loja</th>
                            <th>ID Externo</th>
                            <th>Data Criação</th>
                            <th>Ativo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody v-if="listaLoja.lojas !== undefined">
                        <tr v-for="(loja, i) in listaLoja.lojas" :key="i">
                            <td>{{ loja.id_loja }}</td>
                            <td>{{ loja.nome_loja }}</td>
                            <td>{{ loja.id_externo_loja }}</td>
                            <td>{{ formatarData(loja.data_criacao) }}</td>
                            <td v-if="!idLoja">{{ `true` }}</td>
                            <td v-if="idLoja">{{ loja.ativa }}</td>
                            <td>
                                <div class="is-pulled-left">
                                    <i title="Visualizar Estrutura" @click="ExibirModalDetalhes(loja)" class="btnExibirModalDetalhes far fa-eye fa-lg is-clickable has-padding-right-10" aria-hidden="true"></i>
                                    <i title="Editar" @click="editarSelecao('editar-loja', 4, loja)" class="far fa-edit fa-lg is-clickable has-padding-right-10" aria-hidden="true"></i>
                                    <i title="Excluir" @click="deletarLoja(loja, i)" class="btnDeletarLoja far fa-trash-alt fa-lg is-clickable" aria-hidden="true"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="column is-12 is-pulled-left has-margin-top-15">
            <Paginacao
                :pagina="listaLoja.paginacao.pagina" 
                :quantidadeDePaginas="listaLoja.paginacao.quantidade_paginas"
                :itensPorPagina="listaLoja.paginacao.itens_por_pagina"
                :totalItens="listaLoja.paginacao.quantidade_total_itens"
                :inscricaoFederal="inscricaoFederal" 
                :buscarListaPagina="buscarInscricaoFederal"
                @retornarItensPagina="retornarItensPagina($event)"/>
        </div>
        <span class="is-pulled-left is-size-8 has-padding-left-30 has-padding-top-15">* Campos obrigatórios.</span>
        <modal-confirmacao 
            :class="showModal" 
            :mensagem="mensagem" 
            :titulo="titulomodal"
            :url="url"
            @modalFechado="modalConfirmarExclusao">
        </modal-confirmacao>
        <Modal 
            v-if="exibirOcultarModal"
            @closeModal='closeModalEvent'
            :titulo='titulomodal'
            :largura="'950'"
            :altura="'445'">
            <div slot="body" class="scrollModal">
                <pre>{{lojaSelecionada}}</pre>
            </div>
        </Modal>
    </div>
</template>

<script>
import Paginacao from '@/components/Paginacao.vue'
import ModalConfirmacao from '../../components/ModalConfirmacao.vue'
import Modal from '@/components/Modal.vue'
import { utils } from '@/helpers/utils'


export default {
    components: {
        Paginacao,
        ModalConfirmacao, 
        Modal
    },
    props: {
        editarSelecao: Function,
        selecao: Number,
    },
    data() {
        return {
            payloadExclusao: {},
            inscricaoFederal:'',
            lojasInscricaoFederal:{},
            lojasId: '',
            showModal: '',
            confirmarExclusao: false,
            mensagem: "Tem certeza que deseja apagar esta loja?",
            titulomodal: '',
            lojaSelecionada: {},
            idLoja:'',
            itensPagina: '',
            url: 'https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-lojas-excluir'
        }
    },
    computed: {
        listaLoja() {
            return this.idLoja ? this.$store.state.loja.listaLojasId : this.$store.state.loja.lojasInscricaoFederal
        },

        exibirOcultarModal(){
            return this.$store.state.loja.exibirOcultarModal
        },
    },
    methods: {
        retornarItensPagina(qt){
            this.itensPagina = qt
        },
        
        ExibirModalDetalhes(loja){
            this.titulomodal = `ID da loja: ${loja.id_loja}`
            this.lojaSelecionada = JSON.stringify(loja, null, 2)
            this.$store.commit("alterarVisibilidadeModal", true)
        },

        formatarData(data){
           return utils.FormatarData(data)
        },

        buscarInscricaoFederal(inscricaoFederal, numeroPagina, itensPorPagina) {
            let payload = {
                inscricaoFederal,
                numeroPagina,
                itensPorPagina
            }
            Object.keys(payload).forEach(key => payload[key] === "" ? delete payload[key] : {})
            this.$store.dispatch('buscarInscricaoFederal', payload).then(() => {
                this.$store.commit('inscricaoFederal', this.inscricaoFederal)
                this.idLoja = ''
            })
        },

        buscarLojaId() {
            let payload = {
                idLoja: this.lojasId,
                inscricaoFederal: this.inscricaoFederal 
            }
            this.$store.dispatch('buscarLojaId', payload).then(() => {
                this.$store.commit('inscricaoFederal', this.inscricaoFederal)
                this.idLoja = this.lojasId
            })
        },

        deletarLoja(loja) {
            this.payloadExclusao = {
                idLoja: loja.id_loja,
                inscricaoFederal: this.inscricaoFederal,
            }

            this.titulomodal = `ID da loja: ${loja.id_loja}`
            this.mensagem = `Tem certeza que deseja apagar a loja <b><strong>${loja.nome_loja}</strong></b>?`
            this.showModal = 'is-active'

        },

        modalConfirmarExclusao(confirmacao){
            this.showModal = ''
            if(confirmacao){
                this.$store.dispatch('deletarLoja', this.payloadExclusao)   
                setTimeout(() => {
                    this.recarregarGrid()
                }, 3000)
        }
        },

        recarregarGrid(){
            if(this.lojasId){
                this.buscarLojaId()
            }else{
                let numeroPagina = this.listaLoja.lojas.length !== 1 ? this.listaLoja.paginacao.pagina : (this.listaLoja.paginacao.pagina - 1)
                this.buscarInscricaoFederal(this.inscricaoFederal, numeroPagina, this.itensPagina)
            }
        },
        
        closeModalEvent() {
            this.$store.commit("alterarVisibilidadeModal", false)
        },

        lerDadosLocalStorage() {
            if (localStorage.getItem(`MP-PesquisarExcluir`) && this.selecao === 5) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-PesquisarExcluir`))
                this.inscricaoFederal = localStorageDados.inscricaoFederal
                this.lojasId = localStorageDados.idLoja
                this.idLoja = localStorageDados.idLoja
            }
        }
    },
    created(){
        this.lerDadosLocalStorage()
    }
}
</script>

<style lang="scss" scoped>
#btnPesquisa{
    height: 100%;
}

.scrollModal{
    height: 23.4rem !important;
    overflow: auto;
    height: calc(100vh - 300px);
    border: 1px #dbdbdb solid;
    border-radius: 3px ;
}

td{
    width: 33rem;
}

.column{
   display: table-row;
}
</style>