<template>
    <TemplateNovaLojaEditar
        :dadosSelecao="dadosSelecao" 
        :selecao="selecao" 
        :funcaoEnviar = "onSubmit">
    </TemplateNovaLojaEditar>
</template>

<script>
import TemplateNovaLojaEditar from '@/components/frmNovaLojaEditar'

export default {
    components:{
        TemplateNovaLojaEditar
    },
    props: {
        dadosSelecao: Object,
        selecao: Number
    },
    methods: {
        onSubmit(parametro) {
            let payload = {
                body: parametro.dadosLoja,
                url: parametro.inscricaoFederal,
                selecao: this.selecao
            }

            this.$store.dispatch('resgistrarLoja', payload)
        },
    }
}
</script>
