import { devolverPagamentoService } from '@/service/qrCode/devolverPagamento.servico'

const devolverPagamentoModulo = {
    actions: {
        efetuarDevolucao( { commit, dispatch }, payload) {
            devolverPagamentoService.enviar(commit, dispatch, payload)
        }
    }
}

export default devolverPagamentoModulo