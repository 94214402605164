import { render, staticRenderFns } from "./Paginacao.vue?vue&type=template&id=0b64be18&scoped=true&"
import script from "./Paginacao.vue?vue&type=script&lang=js&"
export * from "./Paginacao.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Paginacao.vue?vue&type=style&index=0&id=0b64be18&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0b64be18",
  null
  ,true
)

export default component.exports