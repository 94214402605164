<template>
    <div>
        <div class="column is-12 is-pulled-left is-paddingless">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#pagamentos-devolver-pagamento"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-8 is-pulled-left is-marginless is-paddingless">
            <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                <div class="column is-6 is-pulled-left">
                    <span>*Inscrição federal</span>
                    <input class="input"
                        v-model="payload.inscricaoFederal">
                </div>
                <div class="column is-6 is-pulled-left">
                    <span>*Id Mercado Pago.</span>
                    <input class="input"
                        v-model="payload.idTransacao">
                </div>
            </div>
            <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                <div class="column is-12 is-pulled-left has-padding-top-15">
                    <span class="is-pulled-left is-size-8 has-padding-left-15">* Campos obrigatórios.</span>
                    <button class="column is-3 button is-pulled-right"
                        id="btnDevolverPagamento"
                        type="button"
                        @click="efetuarDevolucao()">Devolver</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            payload: {
                inscricaoFederal: '',
                idTransacao: ''
            }
        }
    },
    methods: {
        efetuarDevolucao() {
            this.$store.dispatch('efetuarDevolucao', this.payload)
            this.$store.dispatch("ArmazenarDadosStore",{
                dados: this.payload,
                componente: 'DevolverPagamento'
            })
        },
        lerDadosLocalStorage() {   
            if(localStorage.getItem(`MP-DevolverPagamento`)) {
                this.payload = JSON.parse(localStorage.getItem(`MP-DevolverPagamento`))
            }
        }
    },
    created() {
       this.lerDadosLocalStorage()
    }
}
</script>