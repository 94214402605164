import axios from 'axios'
import config from 'config'

function resgistrarLoja(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    let parametroEndPoint = payload.selecao === 3 ? 'loja' : 'loja/' + payload.body.id_loja
    let Componente = parametroEndPoint === 'loja' ? 'NovaLoja' : 'EditarLoja'
  
    const requestOptions = {
        method: payload.selecao === 3 ? 'post' : 'put',
        data: JSON.stringify(payload.body),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`
        },
        transformRequest: [function (data) {
            return data;
        }]
    }

    return axios(`${config.api}/mercadopago/${parametroEndPoint}?inscricaoFederal=${payload.url}`,requestOptions)
    .then(function(response) {
        dispatch('montarMensagemSucesso', response)
        dispatch("ArmazenarDadosStore", {dados: payload, componente: Componente})
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function buscarInscricaoFederal(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    const requestOptions = {
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`
        }
    }

    return axios(`${config.api}/mercadopago/loja?`, requestOptions)
        .then(function (response) {
            commit('buscarInscricaoFederal', response.data)
            dispatch("ArmazenarDadosStore",{dados: payload, componente: 'PesquisarExcluir'})
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            commit('buscarInscricaoFederal', {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}})
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function buscarLojaId(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`
        }
    }

    return axios(`${config.api}/mercadopago/loja/${payload.idLoja}?inscricaoFederal=${payload.inscricaoFederal}`, requestOptions)
        .then(function (response) {
            let resp = {
                lojas:[{...response.data}],
                paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}
            }
            commit('buscarLojaId', resp)
            dispatch("ArmazenarDadosStore",{dados: payload, componente: 'PesquisarExcluir'})
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            commit('buscarLojaId', {paginacao:{pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}})
            return error
        })
        .finally (()=>{
            commit("exibirOcultarModalLoading", false)
        })
}

function deletarLoja(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'delete',
        data: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`
        },
        transformRequest: [function (data) {
            return data;
        }]
    }
    return axios(`${config.api}/mercadopago/loja/${payload.idLoja}?inscricaoFederal=${payload.inscricaoFederal}`,requestOptions)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            dispatch('montarMensagemErro', error)
            commit("exibirOcultarModalLoading", false)
            return error
        })
        
}

export const LojaService = {
    resgistrarLoja,
    buscarInscricaoFederal,
    buscarLojaId,
    deletarLoja
}
