<template>
  <div id="mercado-pago-app">
    <script type="module" src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js" />
    <div class="column is-12 is-pulled-left is-marginless is-paddingless has-text-centered">
      <a>
        <img @click="componente = 'home'" :src="logo" class="titulo-img is-pulled-left has-margin-left-15 is-clickable">
      </a>
      <span class="is-pulled-left has-margin-left-30 is-size-4">
        Integrador para o projeto Mercado Pago
      </span>
    </div>
    <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10"/>
    <div class="column is-12 is-pulled-left is-marginless is-paddingless has-height-inherit">
      <div id="nav" class="column is-2 is-pulled-left is-marginless is-paddingless">
        <section>
          <aside class="menu is-pulled-left is-marginless is-paddingless">
            <ul class="menu-list has-padding-left-15">
              <li>
                <p class="menu-label">Pagamentos</p>
                <ul class="menu-itens">
                  <a :class="{'opcaoSelecionada' : selecao === 1}"
                    @click="selecionarComponente(1)">Gerar QrCode</a>
                  <a :class="{'opcaoSelecionada' : selecao === 10}"
                    @click="selecionarComponente(10)">Buscar QrCode</a>
                  <a :class="{'opcaoSelecionada' : selecao === 2}"
                  @click="selecionarComponente(2)">Listar Cobranças</a>
                  <a :class="{'opcaoSelecionada' : selecao === 9}"
                  @click="selecionarComponente(9)">Devolução</a>
                </ul>
              </li>
            </ul>
            <ul class="menu-list has-padding-left-15">
              <li>
                <p class="menu-label">Lojas</p>
                <ul class="menu-itens">
                  <a :class="{'opcaoSelecionada' : selecao === 3}"
                    @click="selecionarComponente(3)">Registrar Nova</a>
                  <a :class="{'opcaoSelecionada' : selecao === 4}"
                    @click="selecionarComponente(4)">Editar</a>
                  <a :class="{'opcaoSelecionada' : selecao === 5}"
                    @click="selecionarComponente(5)">Pesquisar / Excluir</a>
                </ul>
              </li>
            </ul>
            <ul class="menu-list has-padding-left-15">
              <li>
                <p class="menu-label">Caixas</p>
                <ul class="menu-itens">
                  <a :class="{'opcaoSelecionada' : selecao === 6}"
                    @click="selecionarComponente(6)">Registrar Novo</a>
                  <a :class="{'opcaoSelecionada' : selecao === 7}"
                    @click="selecionarComponente(7)">Editar</a>
                  <a :class="{'opcaoSelecionada' : selecao === 8}"
                    @click="selecionarComponente(8)">Pesquisar / Excluir</a>
                </ul>
              </li>
            </ul>
          </aside>
        </section>
      </div>
      <component class="column is-10 is-pulled-left is-marginless is-paddingless cardPersonalizado has-ajustavel"
          v-bind:is="componente"
          :editarSelecao="editarSelecao"
          :dadosSelecao="dadosSelecao"
          :selecao="selecao">
      </component>
    </div>
    <!-- <button type="button" class="button" @click="atualizarAuth"> Atualizar Autenticação </button> -->
    <atena-utils-modal-loading v-if="_modalLoading"/>
    <MensagemRetorno v-if="exibirOcultarMensagem"
      :parametros-mensagem='parametrosMensagem'/>
    <!-- <atena-utils-mensagens-retorno v-if="exibirOcultarMensagem"
      :parametros-mensagem='parametrosMensagem'
    ></atena-utils-mensagens-retorno> -->
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import {logo} from '@/assets/base64Img'
import Home from '@/views/Home'
import GerarQrcode from '@/views/pagamentos/frmGerarQrcode.vue'
import BuscarQrcode from '@/views/pagamentos/frmBuscarQrcode.vue'
import ListarCobrancas from '@/views/pagamentos/frmListarCobrancas'
import DevolverPagamentos from '@/views/pagamentos/frmDevolverPagamentos'
import NovaLoja from '@/views/Lojas/frmNovaLoja'
import PesquisarExcluir from '@/views/Lojas/frmPesquisarExcluir'
import EditarLoja from '@/views/Lojas/frmEditar'
import ExibirCaixa from '@/views/caixas/frmPesquisarExcluirCaixa'
import NovoCaixa from '@/views/caixas/frmNovoCaixa'
import EditarCaixa from '@/views/caixas/frmEditarCaixa'
import MensagemRetorno from './components/MensagemRetorno.vue'

Vue.filter('toCurrency', function (value) {
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'CAD',
      minimumFractionDigits: 2
  });

  if (typeof value !== "number") {
    return formatter.format(value);
  }
});

export default {
  store,
  components: {
    Home,
    GerarQrcode,
    BuscarQrcode,
    ListarCobrancas,
    NovaLoja,
    ExibirCaixa,
    NovoCaixa,
    EditarCaixa,
    PesquisarExcluir,
    DevolverPagamentos,
    EditarLoja,
    MensagemRetorno
  },
  computed: {
    logo() {
      return logo
    },
    parametrosMensagem() {
      return JSON.stringify(this.$store.state.exibirMensagemWebService)
    },
    _modalLoading() {
      return store.state.modal_loading
    },
    exibirOcultarMensagem() {
      return store.state.exibirMensagemWebService.exibirMensagem
    },
  },
  data() {
    return {
      componente: "home",
      selecao: "",
      dadosSelecao: {},
      COMPONENTENUM: [
        {NOME: 'gerar-qrcode', CODIGO: 1},
        {NOME: 'listar-cobrancas', CODIGO: 2},
        {NOME: 'nova-loja', CODIGO: 3},
        {NOME: 'editar-loja', CODIGO: 4},
        {NOME: 'pesquisar-excluir', CODIGO: 5},
        {NOME: 'novo-caixa', CODIGO: 6},
        {NOME: 'editar-caixa', CODIGO: 7},
        {NOME: 'exibir-caixa', CODIGO: 8},
        {NOME: 'devolver-pagamentos', CODIGO: 9},
        {NOME: 'buscar-qrcode', CODIGO: 10}
      ],
    }
  },
  methods: {
    retornaEnum(code) {
      return this.COMPONENTENUM.find(el => el.CODIGO ===  parseInt(code))
    },

    selecionarComponente(MouseClick){
      let retornoConsultaEnum = this.retornaEnum(MouseClick)
      this.componente = retornoConsultaEnum.NOME
      this.selecao = retornoConsultaEnum.CODIGO
      this.dadosSelecao = {}
    },

    editarSelecao(componente, selecao, dadosSelecao){
      this.componente = componente
      this.selecao = selecao
      this.dadosSelecao = dadosSelecao
    },

    CriarEventoFecharMensagem() {
      window.addEventListener('fecharMensagemRetorno', (e) => {
        this.$store.commit('fecharMensagem', e.detail)
      })
    },

    autenticacao(process){
     if(process === 'development') {
        this.$store.dispatch('autenticar', JSON.parse(localStorage.getItem(`userData`)))
        setInterval(() => {
          this.$store.dispatch('autenticar', JSON.parse(localStorage.getItem(`userData`)))
        }, 180000);
      }
    }
  },
  
  created() {
    this.CriarEventoFecharMensagem()
    this.autenticacao(process.env.NODE_ENV)
  }
}
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.2/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");
@import '@/comum/styles/main.scss';

#mercado-pago-app {
  height: calc(100vh - 135px);
}

.cardPersonalizado {
  background-image: linear-gradient(to bottom right, snow, white);
  border-radius: 5px;
}

.has-ajustavel {
  height: calc(100vh - 200px);
}

</style>