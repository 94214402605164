import { ListarCobrancasService } from '@/service/qrCode/listarCobrancas.servico'

const ListarCobrancasModulo = {
  state() {
    return {
      exibirOcultarModal: false,
      lista: {
        paginacao: {}
      }
    }
  },
  mutations: {
    preencherListaCobranca(state,payload) {
      state.lista = payload
    },
    alterarVisibilidadeModal(state,payload) {
      state.exibirOcultarModal = payload
    }
  },
  actions: {
    consultarCobranca( {commit, dispatch} , payload) {
      ListarCobrancasService.enviar(commit, dispatch, payload)
    }
  }
};

export default ListarCobrancasModulo