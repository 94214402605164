<template>
 <div id="frmHome" class="column is-10 has-padding-left-10">
    <img :src="logo" class="is-pulled-left is-clickable">
    <div class="column is-12 is-pulled-left">
      <p>
        Página desenvolvida para testes na API de comunicação com o mercado Pago.
      </p>
    </div>
    <div class="column is-12 has-margin-top-50 is-pulled-left">
      <p>
        Documentação da API disponível em:
      </p>
      <span class="mercado-pago-doc"><a href="https://meiospagamento-dev.alterdata.com.br/docs/" target="_blank">https://meiospagamento-dev.alterdata.com.br/docs/</a></span>
    </div>
  </div>
</template>

<script>
import {logo} from '@/assets/base64Img'

export default {
  name: 'Apresentacao',
  computed: {
    logo() {
      return logo
    }
  }
}
</script>
