export default class qrCodeItens {
    constructor(objeto) {
    
        this.codigo_produto = ""
        this.categoria = ""
        this.nome = ""
        this.descricao = ""
        this.quantidade = ""
        this.unidade_medida = ""
        this.valor_total = ""
        this.preco_unitario = ""

        if(typeof objeto === 'object' && objeto) renderizarObjeto(this,objeto)
    }
}

function renderizarObjeto(obj1,obj2) {
    Object.keys(obj1).forEach((key) => {
        if (key in obj2) {
            obj1[key] = obj2[key];
        }
    });
}