import axios from 'axios';
import config from 'config';

export const QrCodeService = {
    enviar,
    buscar
}
function enviar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload),
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }

    return axios(`${config.api}/pagamento/MERCADOPAGO`,requestOptions)
    .then(function(response) {
        dispatch('montarMensagemSucesso', response)
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}

function buscar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        params: {
            ...payload
        },
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }

    return axios(`${config.api}/pagamento/MERCADOPAGO`,requestOptions)
    .then( response => {
        commit("preencherQrcodeObtido", response.data)

        dispatch("ArmazenarDadosStore", {
            componente: 'BuscarQrCode',
            dados: {
                payload: payload,
                retorno: response.data
            }
        })
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}