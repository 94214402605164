import { CaixaService } from "../../service/caixas.servico";

export const Caixa = {
    state: {
        listaCaixas: {
            paginacao: {pagina:0,quantidade_paginas:0,itens_por_pagina:0,quantidade_total_itens:0}
        },
        edicao: false,
        exibirOcultarModal: false,
    },
    actions: {
        criarCaixa({ commit, dispatch }, payload) {
            CaixaService.criar(commit, dispatch, payload)
        },
        buscarCaixa({ commit, dispatch }, payload) {
            CaixaService.buscar(commit, dispatch, payload)
        },
        listarCaixas({ commit, dispatch }, payload) {
            CaixaService.listar(commit, dispatch, payload)
        },
        deletarCaixa({ commit, dispatch }, payload) {
            CaixaService.deletar(commit, dispatch, payload)
        },
        editarCaixa({ commit, dispatch }, payload) {
            CaixaService.editar(commit, dispatch, payload)
        },
    },
    mutations: {
        preencherListaCaixas(state,payload) {
            state.listaCaixas = payload
        },
        editar(state, payload) {
            state.edicao = payload
        },
        abrirFecharModal(state, payload) {
            state.exibirOcultarModal = payload
        },
    },
}