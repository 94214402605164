import axios from 'axios'
import config from 'config'

export const ListarCobrancasService = {
    enviar
}
function enviar(commit, dispatch, payload) {
  commit("exibirOcultarModalLoading", true, {root: true})
  
  const requestOptions = {
    params: {
      ...payload
    },
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
      'Content-Type': 'application/json'
    }
  }

  return axios(`${config.api}/pagamento/movimentos/MERCADOPAGO`,requestOptions)
  .then(function(response) {
    commit('preencherListaCobranca', response.data)
    return response
  })
  .catch(function (error) {
    commit('preencherListaCobranca', {paginacao: {}})
    dispatch('montarMensagemErro', error)
    return error
  })
  .finally (()=>{
    commit("exibirOcultarModalLoading", false, {root: true})
  })
}