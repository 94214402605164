<template>
    <div class="column is-12 is-pulled-right is-overlay">
        <article class="message is-pulled-right column is-5 is-paddingless"
            :class="tipoMensagem">
            <div class="message-header">
                <p class="is-capitalized">{{ this.mensagemParse.tipoMensagem }}</p>
            </div>
            <div class="message-body">
            <p v-if="this.mensagemParse.tipoMensagem === 'erro'">Ocorreu um erro ao processar a requisição:</p>
            <p v-if="this.mensagemParse.tipoMensagem === 'sucesso' && this.tipoCrud === 'put'">Atualizado com sucesso!</p>
            <p v-if="this.mensagemParse.tipoMensagem === 'sucesso' && this.tipoCrud === 'post'">Cadastrado com sucesso!</p>
            <p v-if="this.mensagemParse.tipoMensagem === 'sucesso' && this.tipoCrud === 'delete'">Deletado com sucesso!</p>
            <p v-if="this.mensagemParse.tipoMensagem === 'erro' && this.tipoCrud === 'delete'">Não foi possível excluir o cadastro!</p>
            <pre :class="tipoMensagem">{{ textoMensagem }}</pre>
            <button class="button is-pulled-right has-margin-bottom-20"
                :class="tipoMensagem"
                @click="fecharMensagem">Ok</button>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textoMensagem: ''
        }
    },
    props: {
        parametrosMensagem: {
            type: String,
            required: true
        },
        tipoCrud: String,
        detalharRetorno: Function
    },
    computed: {
        mensagemParse() {
            return JSON.parse(this.parametrosMensagem)
        },
        tipoMensagem() {
            return this.retornarClasseMensagem()
        }
    },
    methods: {
        tratamentoMensagem() {
            let mensagemOriginal = this.mensagemParse.textoMensagem

            switch (mensagemOriginal.status) {
                case 200:
                    this.textoMensagem = mensagemOriginal.data
                    break;
                case 201:
                    this.textoMensagem = mensagemOriginal.data
                    break;
                case 202:
                    this.textoMensagem = mensagemOriginal.data
                    break;
                case 204:
                    this.textoMensagem = '204 - Requisição realizada com sucesso.'
                    break;
                case 401:
                    this.textoMensagem = 'Usuário não autenticado ou login expirado.'
                    break;
                case 400:
                    this.textoMensagem = JSON.stringify(mensagemOriginal.data.detalhes[0], null, '\t')
                    break;
                case 404:
                    this.textoMensagem = JSON.stringify(mensagemOriginal.data.detalhes[0], null, '\t')
                    break;
                case 500:
                    this.textoMensagem = 'status code 500: Erro interno no servidor.'
                    break;
                default:
                    // this.textoMensagem = this.mensagemParse.textoMensagem ? mensagemOriginal.message : this.parametrosMensagem
                    this.textoMensagem = mensagemOriginal.tipoMensagem
                    break;
            }

        },

        retornarClasseMensagem() {
            let classeAplicar = ''
            switch (this.mensagemParse.tipoMensagem) {
                case 'erro':
                    classeAplicar = 'is-danger'    
                    break;
                case 'alerta':
                    classeAplicar = 'is-warning'    
                    break;
                case 'sucesso':
                    classeAplicar = 'is-success'
                    break;
                default:
                    classeAplicar = 'is-primary'
                    break;
            }
            return classeAplicar
        },

        fecharMensagem(){
            window.dispatchEvent(new CustomEvent("fecharMensagemRetorno", {
                detail: false
            }));
        }
    },
    mounted() {
        this.tratamentoMensagem()
    }
}
</script>

<style lang="scss" scoped>

.message-body code, .message-body pre {
    background-color: transparent !important;
}

.is-overlay {
    z-index: 999;
}
</style>