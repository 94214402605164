function FormatarData (data){
    if (data) {
        var ano = data.split("-")[0]
        var mes = data.split("-")[1]
        var dia = data.split("-")[2].split("T", 1)
        var hora = data.split("T")[1].split(":", 1)
        var minuto = data.split(":")[1]

        return dia + "/" + mes + "/" + ano + " às " + hora + ":" + minuto
    }
}

export const utils = {
    FormatarData
}
