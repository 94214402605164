<template>
    <div id="frmNovoCaixa">
        <span v-if="selecao === 6" class="icon is-pulled-right has-margin-right-15">
            <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-caixas-registrar-post"
                target="_blank">
                <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
            </a>
        </span>
        <span v-if="selecao === 7" class="icon is-pulled-right has-margin-right-15">
            <a href="https://meiospagamento-dev.alterdata.com.br/docs/#mercado-pago-caixas-editar-put"
                target="_blank">
                <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
            </a>
        </span>
        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-4 is-pulled-left" v-if="this.selecao === 7">
                <span>*</span>
                <span>ID Caixa</span>
                <input class="input"
                    :disabled="this.dadosSelecao.caixa"
                    v-model="id_caixa">
            </div>
            <div  class="column is-4 is-pulled-left">
                <span v-if="selecao === 6 || !!this.dadosSelecao.caixa">*</span>
                <span>Nome</span>
                <input class="input"
                    v-model="caixa.nome_caixa">
            </div>
            <div class="column is-4 is-pulled-left" v-if="this.selecao === 6">
                <span>ID Externo Caixa</span>
                <input class="input"
                    v-model="caixa.id_externo_caixa">
            </div>
            <div  class="column is-4 is-pulled-left">
                <span>*</span>
                <span>CPF/CNPJ</span>
                <input class="input"
                    v-model="inscricaoFederal">
            </div>
        </div>

        <div class="column is-12 is-pulled-left is-marginless is-paddingless">
            <div class="column is-2 is-pulled-left">
                <span v-if="selecao === 6 || !!this.dadosSelecao.caixa">*</span>
                <span>ID Loja</span>
                <input class="input"
                    v-model="caixa.id_loja">
            </div>
            <div title="Somente quando tiver valor fixo" class="column is-4 is-pulled-left">
                <span>URL</span>
                <input class="input"
                    v-model="caixa.url">
            </div>
            <div class="column is-3 is-pulled-left">
                <span>Categoria</span>
                <input class="input"
                    v-model="caixa.categoria">
            </div>
            <div class="column is-3 is-pulled-right">
                <span style="margin-left: 4.5rem">Valor Fixo</span>
                <div style="padding-top:0.1rem; display:flex" class="column control is-half is-offset-one-quarter">
                    <label class="radio">
                        <input type="radio" v-bind:value="true" v-model="caixa.valor_fixo">
                        Sim
                    </label>
                    <label class="radio">
                        <input type="radio" v-bind:value="false" v-model="caixa.valor_fixo">
                        Não
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <div class="column is-12 is-pulled-left has-padding-top-35">
            <div class="is-flex is-flex-direction-column">
                <span class="is-pulled-left is-size-8">* Campos obrigatórios.</span>
                <span class="is-pulled-left is-size-8" v-if="selecao === 7 && !!this.dadosSelecao.caixa">- Campos não preenchidos: Serão enviados como vazio (com exceção de "categoria", por motivos técnicos do Mercado Pago).</span>
                <span class="is-pulled-left is-size-8" v-if="selecao === 7 && !this.dadosSelecao.caixa">- Campos não preenchidos: Não serão enviados, mantendo os valores já cadastrados.</span>
            </div>
            <div class="column is-2 is-pulled-right pr-0">
                <button class="button is-full-width" type="button" @click="enviar()">Enviar</button>
            </div>
            <div class="column is-2 is-pulled-right">
                <button class="button button-secundario is-full-width" @click="limpar()">Limpar</button>
            </div>
        </div>
    </div>
</template>

<script>
import NovoCaixa from '@/model/caixa/novoCaixa.js'

export default {
    props: {
        dadosSelecao: Object,
        selecao: Number,
        funcaoEnviar: Function
    },
    created () {
        this.preencherCampos()
        this.lerDadosLocalStorage()
    },
    data () {
        return {
            caixa: new NovoCaixa(),
            id_caixa: "",
            inscricaoFederal: "",
            parametros: {},
            tamanhoInput: 'is-4'
        }
    },
    methods: {
        enviar(){
            if (!this.caixa.valor_fixo && this.caixa.url == '') {
                delete this.caixa.url
            }

            if (this.selecao === 7) {
                delete this.caixa.id_externo_caixa
                if(!this.dadosSelecao.caixa) {
                    this.removeAtributosVazios()
                }
            }

            this.parametros = {idCaixa: this.id_caixa, body : this.caixa, inscricaoFederal : this.inscricaoFederal}
            this.funcaoEnviar(this.parametros)
        },

        removeAtributosVazios() {
            Object.keys(this.caixa).forEach(key => this.caixa[key] === "" ||  this.caixa[key] === undefined ? delete this.caixa[key] : {})
        },

        preencherCampos(){
            if (this.selecao === 7 && this.dadosSelecao.caixa) {
                this.caixa.nome_caixa = this.dadosSelecao.caixa.nome_caixa
                this.caixa.id_externo_caixa = this.dadosSelecao.caixa.id_externo_caixa
                this.caixa.id_loja = this.dadosSelecao.caixa.id_loja
                this.caixa.url = this.dadosSelecao.caixa.url ? this.dadosSelecao.caixa.url : ''
                this.caixa.categoria = this.dadosSelecao.caixa.categoria ? this.dadosSelecao.caixa.categoria : ''
                this.caixa.valor_fixo = this.dadosSelecao.caixa.valor_fixo
                this.id_caixa = this.dadosSelecao.caixa.id_caixa
                this.inscricaoFederal = this.dadosSelecao.inscricaoFederal
            }
        },
        lerDadosLocalStorage(){
            if (localStorage.getItem(`MP-NovoCaixa`) && this.selecao === 6) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-NovoCaixa`))
                this.caixa = localStorageDados.body
                this.inscricaoFederal = localStorageDados.inscricaoFederal
            }
            if (localStorage.getItem(`MP-EditarCaixa`) && this.selecao === 7 && !this.dadosSelecao.caixa) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-EditarCaixa`))
                this.caixa = localStorageDados.body
                this.id_caixa = localStorageDados.idCaixa
                this.inscricaoFederal = localStorageDados.inscricaoFederal
            }
        },

        limpar(){
            if (!this.dadosSelecao.caixa) {
                this.inscricaoFederal = ""
                this.id_caixa  = ''
            }
            this.payload = {}
            this.caixa = new NovoCaixa();
        },
    },

}
</script>