export const logo = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAABGdBTU
EAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABm
JLR0QA/wD/AP+gvaeTAAAACXBIWXMAAFqSAABakgEfMlTrAAAAB3RJTUUH5QUfEDIQpiYPTQAAKb
1JREFUeNrtnXeYHMW1t9/qmdnZnINW0u4q7ypnkiQQApHBCITBYGOCDRfEZ4NtMhgbw+Vi4wAmYz
BgTDRBZEQQICSBMsqrVc6bc5qZ7vr+ON07PbNZEpir69/z7CNNd1d1dfc5dWKdUhwiFI67GqVUR6
cUkA70R+shWuthaD0YyAf6AOkolaggFqV8gAFoIKS1bkHrRqAGKAN2g9qCokQpVYJSO4AKINjRjT
eufOhQPd5/8B1G4firUUTRngKwANi44pED7tt7sIMrGj+no8OpaF2otZ6E1pNBjVAeI8/w+1M9cb
F+T0KC8iTE401IwIiLwxPrR/liMLweUAq0Vtq0fFYw6LNaW5PM5uY+ZlNTkdnYSKixCaupOWAGAv
U6FNqH1sWglitDLUGpdUApwmBtY1PAhv8wy2ELF3OkA6cBR6DZBsZfgdDB9X0AGD5+jlBgJNLQep
K2rJNBHWv4vMM8iYkpMZkZxPbJwZ+TTUxGBt7kJIy4WAyfD+URhuhE8gCgtQat0ZaFDoawWlsI1T
cQrK6htbSMlv2lBMrLCdbVNVutge1ovVgp4wMMtRCt9xDV93+kyuEHeyJMAx4HzkXo+mPgdKD1YL
55rySIMyO3MYdSBloP15b1PeB7RkzMaH9mRlx8QT7xAwvw5/bBl5SMivG1MYHWdmvXv23HuoAyDJ
Q/BiPWjzc1lbi8/mhAh0KYDY20lpXHNe3YMbxp247hraVlF5tNTZvRvK8MXkWppUCr+xn+wyiHHa
YBZxOe9M1D0WmPJUiEKqWUB8uapLW+VBnGmb7UlL4JQwaTOLyQuH798CTEowyjbfb/xmFLIa01Vm
srraVlNBRvoqG4hEBZea0VDH6iDOPvKPUx0OR++o0rvjlGKZwwB9Xp4ys2rnyw7d1qRYfX9oSRO1
Fze9y+u37cfRTZ2kNHhKO0ZsOqhzvvf+x/geHpYgThd9Lb5wR+ikgQB/OAs4iSIN30wcbdL0N5uW
tEvXlpWoNSY7RlXa08ntn+nOyMlLGjSRxeRExG+rfLFF3AGUeovoHGzVuoW7Waph07m63WwEfKUH
/FMOajdUgh0vBQS5NOPoJDGREzWxSxKfs6E6K0WK3YuOrBLu9h96XatbXR0XN2QTCd9tOTa6pSmy
mb/1RX9zAIO2TazfYai+KVj9C37ySSc47syb2vAB5z/W5jkE4Y2gP47P8H6eC7FK98qGsGiXqwTG
1ZVyilrvLn5PRPnTyBpFEj8CYnSW8HyxQ9tEUOpE+zpYXGzVup/mopzdt31Fmh0AvKMP4EbAr3ry
he9WDv+u/+nSUAxwIzgIEIQewCPkF05Ab7unxEXz4S0aXrgbXAB8AqHEKwmSTqHvHAEcAxwCD7dw
OwBVgMLMMlNR0mKRo3J5pq+tj9jAfyEOL5gz0OBzHAOGAqMAxIRohrP7AGWKxQW3TnfJVpv48j7W
eOAwLAPuBr4AugxHleZYC2wl8TGAPMBIYDfmA3MA/NJyh+Ajzhulcbg7iO+e17nwiMsN81QDWwHv
gI+MrdpkOKHDr+p3iIcR+ahtZ3ehITp6dOGk/q5En40lKFYA+GMWwCtgIBgjW1tFZUEqypJdTUBJ
aF4ffjS0kmJiOdmLRUPPEHrropw8BsbqZuzTqqFn1JoKx8I3AXhvEyWre5iQ9GmhSNuxqXU6AIuB
vxqsRGXdoKvAPcjHzsu4BRHXRZBjwC3EeYmdw4ArgVYcDEDs43IMx4N7DE/YwuJksALkFm4OGEZ1
XTHvs8+/cw+15nEiYsNyxgB/As8ABQFXV+hv2ck1z3cEMjBP8cwpjVrnPxwHXANQgju1EPPIS4++
9zHY9mkAHAb4BzgKROPmE98Jp93XbogEHcerNSKsayrJ9oS9/uz8rs0+fMU0kcNhQUaNPioGAYmA
0N1G8opnbNeoJlZcQpi6QEPwlxMRiGQUtrkLqGFhoDJlZCAvF5eSQXDSOuIB9vQnzvGcVmyEBFJZ
ULFlL39ZpmKxB4FKXuBiqdyw6USVxENxD4J3B0N03WA1n2X2fQwL3A7UAo3hNDkxkAmUkfs+/VHb
YClwGfRR1PRojxcsIqoINWhBk+RJj37whx9wTPIMTsMPVE4JUejhWE0G9EmM6LEOzNiATuCEFgM1
DousbNIP0Qxp3Rw/t/DPwY2BPBIEXjr8bhGaVUcihk3pGQEDsnMyPJX1bTjNGnD0nDhpI4aAC+jH
QMv18UwZ4QqkuF0qZJQ3EJZZ8uILa+msnjBjFt6kiGF+aRmZlMnM0ggUCI6poGduwoY9XqrSxZVs
KWnRWYKWmkjh9LUtFQfKmpYakCHY9DtT0T2rIwGxup37SZsnmfEKqrBdRbhqGuRQgJODAmsRnEQG
bQaMW7CZmVE+nc9gva18UTOcvWITPfx/bvwcAbREqdWmAlMnPnAmPtfhwsBWYBe6DNXvk18NtOxh
JCJMhC4GVEBXRjJ7ANUZOGEzkrhxCG/If9Pp6wfzuoRCTbXiAFmUgKXed3AccjauJpwEtESsigff
9WoD/C6NGYhzC4CfwFYVgHGthAWH0cg0h8N/4K/KLtQw0bdzVGmJDSQiHzj+npSZf8fM6ZavrUka
xYtYWPP/malau3UdEUxJORScKgASQU5OPPzsKTEI/hbe811lqjAwGCtXUEKioJ1tbSUlpG47r1TB
k/kMsumcn4MQPx+br3ONfVNbFqzTbeemcJC78qpk57iR9QQOLggcTm9sGblIQRE4PyGE7AEW2aWI
EAofoGWvaX0rhtB03bd+APNDNmeB6TJw3l3Q9WULxp9xcej/FTYKNzv94yic0gwxFdtq99uBWZfV
9CiP9Y4HogO6r5YuDPCJMWAL8AprjOPw5caf//LkTdcbAV+H/AfESnj7cJ6z6bgBzcgEgMEOb6wD
VOEML9CJFsAeBFRI37J5EhgReBOxBC9gIn2QSV67rmLSQmkQ18jthH2P3+xO7TUUPGIQxfYP9usc
f/GSJ5znH1WwrcBrxn9zUSkTDHRb3PeQhTT0bUWUct1Pa7/B1i+4BImN8QycQVwHlhhdmJOiuVFA
qZf8nKSrns1hvO47STJrS1ME2LXXsqWL5iC4u+3MDa9TsorWoiFBuHPzubuH65xOZki62gIFjfQP
PefTTt2IVVXYURDNDc2ExCnJ/LL5nJj384g8SEaPW8e4RCJptK9jL/89UsWryRrTvLaQxpdFw8ns
QEPLGxKI8HbZqYzS2YjY2o5iYSvIr8fulMnjiUY6eOYtSIfOLj/azbuIvb73yetet2LPR4jB8jM1
evIvBFE+Y4pvRFiDh3RP0/ERXGbSzeZn8gBzuQ2W6N69hE4G3COvdy4AREFfoIMaZBZsj/Av7Wwb
CuBf5EWGItsAmvAWGWe13X7kNcpe8RJlwDeB4433VdMXAKto7uwr12n6bdbgsw3R7v3cgsr+xnvQ
XR9x1k28802v4dQNSjzfaYHcbTiOr1h6h7j0QY0q3CzQPOsN/zja7jS+zj5VF95ALvIszq4AkvRD
CHLxQyb0tLS7z05utnRzAHgMdjMCA/mwH52cw660jKymsp3rSHr1dvY+36HewsXkPN8hYCpgT//B
6DrIxEjh2Vx7Ch43lv3gq27yzjVz8/m/NmTcEw2msalVX17NpdQXNLgOysFPLzsvB5I9Vjr9fDiO
F5jBiexyU/PIFduyso2bKPbdv3U1paQ119E6GQic8XQ3JSKjk5qQwsyGHwoFz6988gKTEu8u0W5X
HXHRdx423PTCnetOd+r9e4VGvKe+UGCF+cT5g5LGT2ao26+jOgETGQnY+2Luqa9Yga4DBIJqJmZE
cRQjnijcsjUkc3EY9QPWEVZAgiMUqAo6Lu97I9VjcyCROtg09pzxwADyNqnINWoN6COgMu1WBZoD
3iSUq2x5tlP8tZiOR1w0LUHrd9tgd4vYN7r0OI263Wavt9RfuI36GNOSzQBlppFGofIlHHua49zt
sWHddgWdZFsbExP5tz5WnqjJMn0hUMw6BPThp9ctI4btooAsEQNTWNVFbVU1/fRMi0SE1OILdPGr
FxMTzw8Nvs3lvJTb88l3O/1952LSuv5ZXXF/HeB8vZX1aDZVnExcZw1ORCLvnRDEaPLOhwHAkJsR
QV9qeoMKxJmKaF1hplKDyGQU8worA/t974fW649enT9++vvsXr9VyvtQ4VjZ/TI1VLK4US+8fvOm
zizJR2UNJ+381E5gjVY1kWHsN9TQh3UFNmYg8y0yW4jmcgUqqjyLEv6tokJF8pjkh1SCPuYCDCy5
WBMIkbm9zXTZx4BY2WD0Qy7GhHJ/JPSEG+RyLdMxCPWAZCwPF0jjwiVbtt2GpRm7s67BhZEf1JEE
Z0P6dFhAr9SHQfG6Lv33ZzpRilNbefdcYRsRfMnkpvEePzkp2VQnZWSsRx07R48LF3+dcbi7jumr
M6ZI5FX27kT3+dy/ad5Uw7ZjiXXnwCKUnxFG/ew7sfLGfOdY9z5eUnc96sY4iJ6d5W8Xh6xhTROG
rSUK766anc84d/XdHaGlxkGOqVA+qoA3Qbsbcs8Hh60lU8kR4nH5F2RlfwIy5ng0gnQAjJmMYTGc
6Pg0h/P22eKZH+y5c/TmdwEd4MxL4a09GTI8Z6Mu2N7ZSo3/WI+tUGpRVaxlxpP4ebQGKIdLGbzv
gjJj1tSdBFzpmu9xvrBVGtgiHzV4XD+g268rKTiemBwdwTmJbF0899wnMvfMoVl53MBbOnRZwPBE
K8+K8FPPq39xlQkMP9f7icIycNw2urVCfOGMs5Zx3NE3+fx18efJPNW/fxs6tOJy018UCG0yPMOv
NIlizdFP/O+8tuBPUFsK+nUuRbQojICHIFEmDrLmtVIcRVTnsvmsa2O9atiEgVsQjbIw584Sa4JU
hnGAjcT6THrRQJyK2x/zYBjyIOgehxueFHiLctbqXDDO3r4LmCRDKUgS2xIr6paptQE4hUU1u9AJ
ZlTfPHeGf96AfTyeuX0YNv1DN8sXA9j/99Hj+6cDqXXXxChM1RWlbDQ4+9y3vzVjDrrKO48vKTyU
hvH7/J7ZPGLdfPpnBoPx545B1276nk1utnM6AguzdD6TFi/T4uvfgEliwvmVheXvsDj8f40zdyow
NHJaLfO6rJXuAKrXSFcn1pFyKJzFIWSqfQM9QitlK661gehCnRxRzDkGi+k9nRiDgZTiGSOdYizo
DlhAk9iY5jHJVRv/siUqWlg0lrEJGS1bLHvx8Yah/z2OMEJLDrCaVhqRonOjCMSCbbYwBe07QuHl
6Ul3zSCeMO7JN1gn2l1QSDIfr1zaCmppH6hmbKymt5b95y5lz3GJ99sY4brpvFDdfN6pA5HHi9Hs
6fPZV777qY3XsquPaGJ1m+csshHasbo0fkc+LxYwF+iO2O7S7J7VuCgfj/3R6YQcA4pZVGY7r/FF
hovBF/qleuhzJE73djCpCsgcLId3I54s5+2v73dmRGHhnVfi7wJZGL3LKBHNdvC5kENhFphw3AFa
x0fZMk4OSo++xBovHLo46fhuPyVQrT18YcWYi72o1FBjDc4zFOOHnmBNJSEjiUmDljHKedNJEHHn
6bH/7kz1z80/u56LI/85v/fonMzBQeuO+nnHfOlDaVqjtMPXo4f/n95SQmxvLLm//O+x+u6FG73k
IpxemnTCI5OX6UZVnTDr7HQwYnl+tL17FExIc/xjAj7PQcrfk94v6ci8zmt9O7JQ6NhIOTDo5Egm
4JClAioI5AYh5ufK5F/fNHHY/Wj33AxUTaUdXIzL8OcSs7iENiSENcx+KBnyHp7g4sxD0M8CaRLu
WjkBiSW1XKRFzvbs9ULfCC1zStEzIzU3KnHh3tZTt4ZKQnccct51Ncspf1G3dRUVlHXGwMRYX9GT
d6IHFxMb3us3BoP/54z6Xc+6fXuOPuFyktq+HC849r5wo+WIwo6s/Qwbm+pcs3z9Ta96rHEzz4Tg
8NLCQyfTKQah87BnjT8njmIzNnIhKdPiKq3Qv0foXdSwgBD7Z/+5AI/AnABo1KRohzgKvNbuBJW1
eJlkDnI4HNFYjUmIUEAt2qzeeIKzmAROPHuc4fhxD9pwgDj0WCr25DaDnhHLKFwKtIvhmImnWt3W
alfWwiEldyq3mvAp94tdbTszKTPUkHELDrCXw+L6NG5DNqRP4h6zMnO5U7b7+Qhx9/l78++i6lZb
VcfcWpBxR07AyJCbGMHjWAZSs2T/R4gqnYXp7vCOYjKSK/IzwjFxAmgmhYSMDw+Z7eoGTNkwwdfT
nIDH4XEil37uVHPFMd5TY1IBLNIb73EInjuFv7IKkfTXY/0RKtGPg9YeP6aYSYz3ZdM5z2cRMH+x
EGLrV/B+zxFxGO/XiQCPvkTvr4Eglutnq9Xs/I7dv3M+faRxg1soAxowZQVNif/LwsUg+xynUokZ
gQy3X/73vkZKfy8BPvU1Zeww3XndPOzdwbBEMmZeW1bN6yj3Xrd7J8RQkej9EfmelqetiNexby0s
naIiINys780p5O/m8hBLsHuAmZYTvrY6997QOEA5bR9283TjPU4v75D/vfWwgbvB2hGCGsf7Yd0a
xEcTPw34TTWhSR8RkQaTAfuJPImEY1wmClwHlEOgwihozEcu5AAn5ubAEutcd2KqKqdYRmhKFvxc
7L86J1H1/BAPYmJbHly2288eHXxHugX580Ro7IZ/LEoYwZPYD+fTPx+Q6tGnOw8Hk9/PiiGWRlpX
DvH1/jhluf4fabv8/ggX163Ed9QzNbt+1n+cotLF+xmeJNeyivbiDo8eGJjcXweBK1ZWUSqQt3hU
8Iu02tTtrtQT6WE5NY1sHSIwshzCX2mVoiU8BNJE9pAaJuzUDUoATCayy+At5GsSaq7xbErdqP8I
KlTUTBFTA0kZn8c8QrdTRiM8QhTLcbWIREsyNVKgUYxjNY1hpEnRqLqIYeexzlSIBugT3exk7e1z
XAU/ZzjkUmLR8isbbZ7T/C5cCISuvfiKiKxyLrQYYTVlFr7DF8hGQ5tDkGVNG4q82c008x0qccTa
ixiVBtLS379tO4czfNu/ega2tJT/Axang+06aO5OgjC8nrn9Vhmsi/E0uWlXDnPS/h8Rjcccv5TB
g3uNNr6xua2bBxNwsXb+CrpcVs2V5KYxC8Gekk5PUjviCP2NxcgtU17HnxlVartfVMlPqwq1hI4Y
RrnEh6h+gg8hsBHQyhYnxsXPFg98tC2z58BFc5s7IPIegmouyNKILpcpxudNBGIcztte/VQlS8pI
vn9SEBPGWPL0D7WEu4/YSrQbejNQNRzwzaxzraPUcnzxxDOAga6KwPVTTuap196klkTJsiKeNKyq
hoy8JsaSFQUUnjth00lGwhWFpKdlIMRx9ZxBmnTmLCuMH4/V0Gib5VbNq8lzvveYm9+6q45frZjq
sWgGAwRMmWfXy2YC2fLVhLydZSWgwvsf36kjh4EPEFecRkpGPExkpavlI0bd3OrmefC1itgbNQ6o
PvULCwDT1xPx+qccu9Ol7AaoUUhld3eK/4My4kf09at/13N86i8XO6WQes2biy8zXxwyfM6X75kK
XY+HV4ZakqHHuVlXn8cSr7pBPQVhQju9ZwWIEArWXl1BeXULduAzGNdRw1cQg/uvB4Ro3I58ulm/
hq6SY8HoMpRw/n6CMK0VpTWVWPZWnS0xIPGTNprVm9djt9czPIyozMTthfWs09973KkmUlXDvnTG
ZMH8OyFSW8+/4ylq/aRm0I4vL6k1Q0jPiCfHypKRg+n7x01/Mrw6B+QzF7Xni5VYdCZ6HUvO8ig/
wH3yxU4ZirWlImjPX3PfdsUN2oTYYCDWZDIw2bSqj8ahnxTXUMHpDNjp1lFORnS0r87gpmnjCOmp
pG1q7fgdZQkJfFBedN48Tjx3S59ryn+NvTH7Js5RZuvG4WAwfkRJyrq2/i/ofe5q33lpKZnsTeig
bIzCRl5HAShw7Gl56O4fV2udBLGQZVi7+i9K13m1HqNODT/zDI/z14UdQGyiuyzZZWPPFxXa8MtO
ScJzGB1InjSSwaRul7H7Ju/Tp+ffP5nHbyRCxL8+K/vuBPD7zB8KI8Lvz+sSQmxrH4q43cec9LVF
XVM3BANkopBg3oQ0ZGUg+HGokf/mA61TWN3HzHc9xy/WzGjApn+yYnxXPDL2aRlprIo0++T+q0qW
QeOwXD748oRNcVtGXRur8UrXWLUqr2QMZ45juleHoQuH7jjJwOj5/77l5Mq2vHiDY0c0/r2Clx9t
uldAdTm7x1Zt9Oz/ekD60Vc8/8ZlJ//t1QReOuXmbExk7Mu/hC4gcUdEs4EY0Ng73vvE9hawWPPz
QHf4yoUNU1DTzzz/lcMHsqfXJE9wwGTX5z9wu8+e5S0tMS29SuS390AmedPhmjh2npbrS2Bnngkb
f5/It13PSr2Uw5KnLVZGNTC1dc/RCbfWn0nXVmz9evK4XZ0MDOv/+Dln37tyrDmA7s6qkE6YSofI
S9Vk4qe8SAHEbppL2XyDI17QJ+XbR30t59iEHciBjW7dp28Qx+JKXDb9+7gSiPk1aKuacfXoziRa
nVVkvLxIbiEuIKehfM01qLKyJkYpnhb52Wmsh/XX4ysbHhSLnP5+H0UyeRnp7EKTMnEAqFmPv2Eu
67/w0y0pOYNmVErwfv9/v42VVnAPDr3z3P9dfO4pSZ49vOx/i8xMb6sFqCvSruoJSiaecuAhWVKM
PYQfukuU4RRVixSImcmUhOUgZCpC2Ib/59xDVa20FbEDfqVMS1WUh42WgtsuhpARIpruykfSESYD
sGcenGIcRdhrhUX1WtaSu1v1raaoM3zsyK7mcEEume6uojiLhTVyJLZb8Agkprzn67tFOJ+L8Rns
zcIzKA08ymJk9i4TA8cXE9bqwMqfu1c9FyEmJ9DByQQ319Myu+3kr/fhl4o9Y39M1N55ijisjOSq
FPThpHHVnIsuWb2b23MsLj1BH2lVbz2tzF5OdlRaSoeL0eJo0fQmVVPX97+kNiY33065tBQ2Mzr7
y+iLc/WEHyEZOJ65vbYybRwRAVn3xGy/79KKVew15pV7F/aZftoghrKBIxvgMpQDAMiR30RVYdjg
W+Z/+7AslbciPPbv9bu/1wJJ1jABIVPgbJf5qO5GeFszfFxLsQeBIJrhXa981C4geDkHjAWXhbPA
ihB1Gaoguvb6MNJOv2USR+MQRJKkxHcpcKkLysWXa/S5BAGxufd1ff+d8NL7AQpXYHKioH1q5cRe
aM6T1urC2LhEEDaTnyCB548kPeeOtLgoEQaelJjB87qE3lchC9kMkf42PYkL6s+HorFRV1ZGQkdW
rApyTFs2nzPn5z9wvcesN55GSnhvuxJUltXRP33Pcqz7/4GSHTYl9VI4kTJpA8cniPVUdlGDRsKa
Fx8xaUYbQiOT+9xSAksHZMD97/6YjqciF2xREkAe/3wAXdtI9BgnaPIMtWZdmuZiayBiOzm/a5SI
Q7BWFkt9p2KbLIKb6bPpKBnyOS5Vqg+XCSIp7M3CNqgLFoPS5QWUX8AHF99nS2VUoRl5+HPy+POk
8sNS0hQpWVnDJzPCnJXb9brTVvvbeUBYvW8/nCdWwo3k2/vulkZrSv4uLzeZkwdhAffbqaDz/5mk
kThpCUFJZ2Xq8Hr9fDex+vIpA/GKtgIOnTjiFl3BiUr4fuZaUwGxope/d9AuUV2Nsp/A/QqHT3Es
SefT3APUTmDoFEez9GiDiGyGzSAiTg9pH9eyZCsE6eUgCpT/UCEs2uQKSQI0rTECnyBcJsDxC5Bq
MJYfQPkXT5HMLpFgaSqLeMsBQag0gON4OVIkXVXkPWdKREnR+DpGd83aDi2Pr8PT17599xGMiHeQ
mlmkK1tZR/PJ9QQ2P3Ll8XlGEQP7CArOOm0ufkE6logaef/Zi6OonY19U3U1XdvjBgIBBi4IAcrr
vmLE4/ZRLrN+ziFzc+xdr1OwFZVBUMhlO409ISue2G82hpCXDLHc+xe0/YNNBas2LFFoiNJ2vGcW
RNn0bcgAJRA3tqf1gWVQsX07R9J4jT4FVEX2fDqh67eIuQ2dyNecg6hPMRqXAK7YsP/IDwYp4TiV
wq+gowG0nC+7Xdx1NR7Z26UkcSKblakKoeZwFXAd9HVCe3Spdo39+ICfhByt+4DdLtSLWWS5BcqZ
/Z/c13XeOz+09P1M09fVffeXgyc48ASWibhFLDglXVaNMkYdAA2b+jp7Ddp574eDzx8Sybt5gli9
eyfsNOnv3nfEKmxbgxkYX1vF4PE8cNYcK4QUyaMIRjp4zk84XrWL9hFzOOG8OTz3zEV8s2Mc5VNy
spKY4xowYw990lfP7FOlKS4yktq+WZ5z7h5bmLSTxiMkmFw3pfFlUpalesomL+Z+iQCUptRRIBq6
BX9sdJwI8Ih5srkbKeq7CrGCM5VWsRInPEZbJ9zUpkmWq1/f+VSMUQd46ThRjNx7qObUDzKoqLEQ
nkYD7CIM1K4lhO0bS+RFY2MYCXTI+ZimQJu6XD72ifCVyFSKNZhNd8ZCISbkvRhdcfFraIYxQ0IW
K5FqBmyTKqFixCm2avJAkAWpM8ZhS5F5zH1tgsXluxlxXbKlmwcB3VNWGvYFV1A8GgGdF9bp80vn
/uVNZt2El5RS0XzJ7K6rXbuee+V6mvD89KQwbncs2Vp/H1mu384pZnmHPDU7w8fz0Jxx5HxpTuqn
12AKWoX7+BsnkfY7UGnGd+AvEUUbLmqd70lkdkLsZaYHUH121EisW54RQ1eBRJrLsUYa7thAs3X4
rYHFe261HhpX2FwLYtH14/LcIF+yaRq/UyEPVssP0MDioQ1Qxo5w5eTmQlkFjsRUffrUy9A4c7F/
8TxLD8uQ6FqPhsARpNxtRjRIfvZbHouLz+xPXvB5amaedOlr7+Jr+44W+cevJEWloCzF+wlttvOp
9BUVHw9NRETNOipSXA0CF9uf3G73Pj7c9y1+9f4ZbrZ7fZNTU1jRg+H+knziC2X198qSl4k5IOqJ
h2/dr1lL7zPmZDg8McnyMeIADMUE9UhrYsoWg34C5s705UnMJECqO5kY1MWhYiUc6y/0bZ5xJoXw
jbgUbUHPfM79TGCl8U3odkJyKlHEPRZ//lRj1DBbaa6Yz/jTNynGdosPtx157KcwZzOMAbldL8B2
AySh2jg0EqP/0cs7GJzOOPw5OYEJGr1C0cQjUU8QMHkDt7FmsWfsmKR+ehtcZsaGDZ8pJ2DPLl0m
ISE2NJt9eoDxmcy29v+wG/vOkpbrrtGY45qoidu8p578OVxI8eRerE8RgxMQdUyFqHTGqXr6D84/
mYjU0Oc5Qien45HJJEvxY6p5fo1G6vfW0u4oU6h/ZFpS2EYBuIXHoK7cv5mM49HOKee3obcbfSvq
AdhLNkHQToZJNUe6wtUce+mZV3/yZ4AbS2UFIQYw/wK+AFlCrQpkX1l0sIVlWTNXMG/tw+8k56Oz
1oTVx+Hv379cVsFJqo+Hwhjz35AfHxfo6cXEgoZDLv45W88voiLEvz5wfmMnXKCDIzktm5qxy05v
PVu1m4qRzD7yflqClkTBiH8vl6Ff0HcSoE6+qo/OwLapatQAeDDnO0IKvhPutVh13DKSUTHmQ4IT
baXddkX3sTEr9w0IgEBJciXrBViHs4ugSniS2tbBjY0sBxvZ7zZqkzkHg6XjgUXRsqHpvoZ71Vxu
uRKSWeDp6hnsMIXoDiVY+406YXI/7sx7ArejRsLKa1vIKMY6eQPGY0nlh/r4kSrVGGgTc5GRRkHX
8cZR+Z/PreV8lOjcM0LcobgiSMn4gnPo63lq/nrflr8aIJovDn5VFwznS8SYkojwfD72/rt8dQCi
yLhk0lVMz/nOYdO+WYMEcIqS/bVuP2ECUnDkGIqCYqkBiDRKnd2IlEq7/nOtaMTFpPEzlbnxb9dC
gCaPZGfd8Iz4gVlg0Dab/Ph1MUooFw0bZsJDi516lB5XqOdMJr1R1scTo6HNBmg0QtpnkD+YAPAD
kYBsGqKkrffpeG4hLSpxxFfEG+FIg+AEZBgychnj6nn0LL+LE07ytFeQxy+/UlNicb5fGQNmkCwe
oanCTKmPS0cDzjANQpgEB5BdVfLaV21WrMpiY7EwAQFeIPSMwj5LyPQ4RRiGfr5ajjRxPpjg0hEf
V+RNoR24F/0V6ViTbGm9BYiOF8qev4Schk1+QibANR36LVIQOxi0oIl9dJRlzDiwEdxeQn46ozhT
h5lsIh2kHzO4CIBfNRTPIyItrvBwajFNq0qF+/geYdO0kaMZyUSeOJ7Zvbbep4h9Aa5fEQV5BPvJ
0D5s60Nfx+/Ll92jbnPJDdrJRhoC2LQGUldV+voXbl1wSrqtvO2ahHosl/xtbJ926ZeyjfcZzdv4
WobiayhvxeItdXb0GKBQwh0gmUgCwNdcctTqC9BHGW9n6MLIF1yujMQNZYP4EY5cmIG/qijl4ZQu
SvEblZzmWIdPuHfd6PpL/cQWRp0gXIVmrMPUwi6e1qJEUxyTtIlYj7kJwf2cqsqYnqpcuo37CRhK
GDSRkzmrj8/hjxtkOkN1LFsjo3aXq4RXQEXNu6tewvpW7deurXbSRYVdV23oVtCPG8hG0jaA11db
sP9XsejGyJsA2RFAW0rzv7DLKOPIFI71Ie8EdkazILkToXELmvx17aChXojaCeILwxTgxi01yExG
RS7ft3FeR6FolvOFU/khDpeglip6YiktFd46oamUybOIzQqbs6ailnDqIHX4HbKLMJ2PDHEJubS2
LhUBIGDyImK1N2n3LP/t/YE7hWPQaDBKtraNq+g4aNm2jeucv2ThHNGCaSRXsH4fI0B6VWnf12Gb
b34reIF8xBCaIydbXm9E1klq5EJq0HaR/ncLSWaMJuQrYne8B1LBXZt+/CLu65BYl9pNq/y5BJ0I
lrTEei9T3ZNq0JmWjud17CYZOL1dmJiv1LsaPsEK6wtxIR3fmAaiNOyxLC3LqN+vUbadqyjUBlFV
YwiDIMDJ8X5fGgDCOcjKhU76JJtjHt7kNbFmZDIy379lG/dh1VXyySvQdXr6W1vBwdCskqyEjmKE
GI+A5c5foP1uZwZcEeT+RuR28gBvYE2nt8GpHdmn5JuI6ThXip8pGMYOcbOdsmOwjY191q9+8W2y
2EPXFDiJzpQ0he1t32OFNdY3masCq3HcnQzUWkVUdV/kJIEPQWhJksOHyYA3pAosNGX4PhjZAAaU
hO0RVIqnakw8KlFhk+H96kRNmlNisLf1YmvvQ0vElJeOJiZbs0rxcconcTstOPpdFmCCsYxGpuId
TQSLCmhkBFJa1l5QQqKgnV1WG1tsr6FKU6i/7vRtIlniAqQHcoDPIuJIizddpoxGAuRGIVexBC/Y
L2BjiIWjMTIeL+iEFtIiVqtiHJhV/SfqekaIy0+xho3+drJClyiP2vo+rtsq+LroSYiKhaR9t9JC
LMuQdxCCxE1HDg8GIO6MUc3kH1jD6IO/IixKDreCGJi2GUYaC8HowYP0asX+pOxcZi+GMwfD7J/X
L2FrQsdDCIFQhgtbRitrRgtbRgtQawgsE271kXDAEyo21Ckg5fALXOHcQ5lGvMu2CQJ5DJJGpY7R
2hrgh1NDx2A6eGVfgh7JiKq20OkWVAm5EtBqL13NuRxEMHi5EkyrouxgHRMZ2o8R9u6HEhY3edI6
1BKfYj7sMXEcNxFqJeDIjo12UjAOiQiRlsxGxsJKh1j2OOEaoZEV6ojuDsP/EGkkdkW91yN21aFK
9+5Ft/2WGEx+6L8fHKSWFnVjgdpY3hQJiinee0E4IchjgdkuwOAoiT5WFkt1wv4cxeNz61z7frO4
pZIpgj5PHy9qmHbsuM7xp6vVNOBwXBapFyje8jqsCRCKMcgSwcSiNaUrmI/RAltTUiKsIqwmpLCd
G7EWGwYeVfv9EX2gHaCKo3M+wbZxzw2u7liO3gDjbeiUTeVyOSfwZh2wNkAnmx87EcfpKhpzjgra
QcRim0i3khs9Uu++9fCGMMRNyBo5HAVj6yPDOJcGW+nsLZM6IR8fbsQZjA2aWoBPHEtJtpv+VyPW
7RFkSI9dtEE2KAjya89bIPSY/vaG+9ALJ6cTWA4T1cYuCHBge911pxRInHq3EJi2r7zylE7EO8OM
7GkFn2v6kIwzj74Tk6bgjRnxsRw7QKMUjLEQapJTLvKAL/xhpWy5GYRhCpV/viwXV3QFiKbGjzP4
iB3RnVlyEZBI85B147Javbzv8v4RtN2x82YQ7Gt5T3/O8u6nbusxuJycxAmyZaKVqIfLnflpoSZS
/0R1YKnoEUfXCMnTIkpf9xZEHVYRW7OJT4/0h3QWMdHgTsAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMD
IxLTA1LTMxVDE2OjUwOjAzKzAwOjAwpZnN6QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNS0zMV
QxNjo1MDowMyswMDowMNTEdVUAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAA
AAAElFTkSuQmCC`

export const btnExcluirImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABHV
BMVEX/////7u7/ZGT/Fhb/Fxf/FRX/X1//6+v/+vr//Pz/rq7/LCz/lpb/s7P/mJj/qKj/2tr/bGz/ODj/GRn/W1v/YmL/XFz/Ghr/NTX/amr/1tb/+Pj/eHj/AAD/cXH/9vb/9fX/iYn/IiL/MTH/Y2
P/NDT/ISH/hIT/8/P/XV3/f3//39//yMj/6Oj/wcH/5+f/ycn/3t7/hob/WVn/+fn/YGD/g4P/o6P/xMT/Pz//wsL/np7/ior/9/f/nZ3/T0//wMD/MzP/vr7/U1P/Wlr/VFT/goL/iIj/gYH/nJz/TU
3/v7//vb3/UVH/l5f/gID/w8P/j4//2dn/fX3/2Nj/kpL/u7v/urr/WFj/ZWX/Kir/GBj/Gxv/KCj/trYSw3KbAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+UGEhEMIHUy7KYAAACySURBVAjXY2AAAkYmZh
ZWNnYGCODg5OLm4eXj5ufkAHEFBNmEhEVExcQl2CSlGBikZWQRQE6eQUFRSVmFSRQImVTV1DUYGBQ0tbR1dPX0DQyNjIFcBhNTM3NjC0srTWsbEVsQn83Mzt7B0cmZz8bFFiJvbe+g6uTKpwiRN3XDwX
eH8j08vbyVfXz9YHz/gMCg4JBQbyOweZyCzmFhTuFOYWERkWAPhEdFx4gDYXRsHAMDANBDIa5oAasrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA2LTE4VDE3OjEyOjA0KzAwOjAwo5WR7QAAACV0RV
h0ZGF0ZTptb2RpZnkAMjAyMS0wNi0xOFQxNzoxMjowNCswMDowMNLIKVEAAAAASUVORK5CYII=`

export const btnDetalharImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABIF
BMVEUDAQQAAAADAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQ
QDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQ
QDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQQDAQT///+7fIiDAAAAX3RSTlMAAJTp5b4X1msmKik7zTnYSRDEPddRfa+tjQsSxU5LaVYGE1B1pa
KkhAjGPk9UeHZ3YSVfJGjBiQ0wwzojtFlHBXNKGLeKdOJw2QIEkJim4K7JsbKws3taH11nOAyZhpSxX0wAAAABYktHRF9z0VEtAAAACXBIWXMAAYAJAAGACQEBnWqsAAAAB3RJTUUH5QYSEx4i4EwpNw
AAAK5JREFUCNdNitUWglAUBQ8mtphYoKLYYjd2i935/5/hBXHpPO1Ze0ChVMko1RrAQIvr9BIGo8mM3GIFGcJmR+5wutwkSbo9Xp9f8gCF0zSNB0Mft1DfPvz5mUiUZdlYPEEkU6KnM1mO43L5gqFY+u
vLlUq1Jv4UYBgG9Uazxbd/3uG74OmhztGX8sFwNJ5M0Zgx8xBiUReWqzUDsNnu9iKHw/EkrM9wud6+3B/P1xvSnRthS3cOuwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wNi0xOFQxOTozMDoyNSswMD
owMOG4+GgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDYtMThUMTk6MzA6MjUrMDA6MDCQ5UDUAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==`