<template>
   <div id="frmBuscarQrcode">
       <div class="column is-12 is-pulled-left is-paddingless">
            <span class="icon is-pulled-right has-margin-right-15">
                <a href="https://meiospagamento-dev.alterdata.com.br/docs/#pagamentos-buscar-cobrança"
                    target="_blank">
                    <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                </a>
            </span>
        </div>
        <div class="column is-6 is-pulled-left is-marginless is-paddingless">
            <div class="column is-12 is-pulled-left is-marginless is-paddingless">
                <div class="column is-12 is-pulled-left">
                    <span>* ID Transação</span>
                    <input class="input"
                        v-model="parametros.idTransacao">
                </div>
                <div class="column is-8 is-pulled-left">
                    <span>* Inscrição Federal</span>
                    <input class="input"
                        v-model="parametros.inscricaoFederal">
                </div>
                <div class="column is-4 is-pulled-left btnBuscarQrCode">
                    <button class="button is-fullwidth"
                        type="button"
                        @click="buscarQrCode(parametros)">Buscar</button>
                </div>
            </div>
        </div>
        <div class="column is-6 qr-code">
            <div>
                <span>QrCode obtido</span>
            </div>
            <img v-if="dadosQrcode.imagem"
              title="Clique na imagem para aplicar zoom."
              :src="`data:image/png;base64,${dadosQrcode.imagem}`"
              @click="qrCodeZoom()">
        </div>

        <div class="column is-12 is-pulled-left has-padding-top-35"
            :class="[!dadosQrcode.imagem ? '':'resultadoBusca']">
            <pre class="is-pulled-left is-fullwidth">{{ dadosQrcode }}</pre>
        </div>
        <span class="is-pulled-left is-size-8 has-padding-left-30 has-padding-top-15">* Campos obrigatórios.</span>
   </div>
</template>

<script>

export default {
    computed: {
        dadosQrcode() {
            return this.$store.state.QrCodeModulo.qrcodeObtido
        }
    },
    data() {
        return {
            titulomodal: '',
            cobrancaSelecionada: {},
            parametros: {
                inscricaoFederal: '',
                idTransacao: ''
            }
        }
    },
    methods: {
        buscarQrCode(parametros) {
            this.$store.state.QrCodeModulo.qrcodeObtido = ''
            Object.keys(parametros).forEach(key => parametros[key] === "" ? delete parametros[key] : {})
            this.$store.dispatch("buscarQrcode", parametros)
        },
        lerDadosLocalStorage() {
            if (localStorage.getItem(`MP-BuscarQrCode`)) {
                const localStorageDados = JSON.parse(localStorage.getItem(`MP-BuscarQrCode`))
                this.parametros = localStorageDados.payload
                this.$store.commit("preencherQrcodeObtido", localStorageDados.retorno)
            }
        }
    },
    created() {
        this.lerDadosLocalStorage()
    }

}
</script>
