const storeConfig = {

    state: {
        modal_loading: false,
        exibirMensagemWebService: {
            exibirMensagem: false,
            tipoMensagem: "",
            textoMensagem: ""
        }
    },
    getters: {},
    mutations: {
        fecharMensagem(state, payload) {
            state.exibirMensagemWebService.exibirMensagem = payload
        },
        exibirOcultarModalLoading(state,payload) {
            state.modal_loading = payload
        },
        preencherMensagemWebService(state, payload) {
            state.exibirMensagemWebService = payload
        }
    },
    actions: {
        async montarMensagemErro({commit}, payload) {
            let message = {
                exibirMensagem: true,
                tipoMensagem: "erro",
                textoMensagem: payload.response ? payload.response : payload
            }
            await commit('preencherMensagemWebService', message)
        },
        montarMensagemSucesso({commit}, payload) {
            let message = {
                exibirMensagem: true,
                tipoMensagem: "sucesso",
                textoMensagem: payload.response ? payload.response : payload
            }
            commit('preencherMensagemWebService', message)
        },
        ArmazenarDadosStore(commit ,payload){
            localStorage.setItem('MP-' + payload.componente, JSON.stringify(payload.dados))
        }
    }
}

export default storeConfig