import axios from 'axios'

function autenticacao (dispatch , payload) {
    const formData = new URLSearchParams();
    formData.append('client_id',payload.client_id)
    formData.append('username',payload.username)
    formData.append('password',payload.password)
    formData.append('grant_type',payload.grant_type)
            
    const requestOptions = {
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    }

    return axios(`https://contas.pack.alterdata.com.br/auth/realms/alterdata/protocol/openid-connect/token`,requestOptions)
    .then(function(response) {
        sessionStorage.setItem('autheticationToken', response.data.access_token)
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
}

export default autenticacao
