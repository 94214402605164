<template>
    <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head p-2">
                <p class="modal-card-title">{{ tituloModal }}</p>
                <span id="btnfecharModal" @click="fecharModal(false)" class="fechar has-text-weight-bold">X</span>
            </header>
            <section class="modal-card-body pl-2 pr-2 pt-4 pb-4">
                <div class="icone">
                    <span class="icon">
                        <a :href="url"
                            target="_blank">
                            <i class="fas fa-question-circle fa-lg" aria-hidden="true" title="Obter ajuda da documentação"></i>
                        </a>
                    </span>
                </div>
                <div class="pb-4">
                    <span class="mensagem" v-html="mensagem"></span>
                </div>
            </section>
            <footer class="modal-card-foot pr-2">
                <button class="button is-danger" @click="fecharModal(true)">Confirmar</button>
                <button class="button" @click="fecharModal(false)">Cancelar</button>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            mensagem: String,
            titulo: String,
            url: String
        },
        computed:{
            tituloModal(){
                return this.titulo ? this.titulo : 'Confirmar Ação'
            } 
        },
        data(){
            return{
                tamanho:'is-medium',
            }
        },
        methods: {
            fecharModal(confirm){
                this.$emit('modalFechado', confirm)
            }
        }
    };
</script>

<style scoped>
    .modal{
        border-radius: 0;
    }
    .mensagem{
        font-size: 14px !important;
    }
    .icone{
        display: flex;
        flex-direction: row;
        justify-content:flex-end
    }
    .modal-card-foot{
        background: #ffffff;
        justify-content: flex-end;
        border-radius: 0;
    }
    .modal-card-head{
        border-radius: 0;
        background: #2e4283;
    }
    
    .modal-card-title{
        font-weight: bold;
        color: #ffffff;
        font-size: 16px !important;
    }

    .fechar {
        margin-top: 3px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(16, 135, 247, 0.815);
        color: #ffffff;
        cursor: pointer;
        border-radius: 50%;
        width: 23px;
        height: 23px;
    }

    .fechar:hover {
        transform: scale(1.2)
    }
    button{
        font-weight: bold;
    }
</style>