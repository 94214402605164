export default class NovoCaixa {

    constructor(objeto) {
        this.nome_caixa = "",
        this.id_externo_caixa = "",
        this.id_loja = "",
        this.url = "",
        this.categoria = ""
        this.valor_fixo = undefined
        Object.assign(this, objeto);
    }
}