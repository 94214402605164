<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :style="styleObject">
                    <div class="modal-header">
                        <span>{{ titulo }}</span>
                        <span id="btnfecharModal" @click="fecharModal" class="fechar">X</span>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                         default body
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        largura: String,
        altura: String,
        titulo: String
    },
    data() {
        return {
            styleObject: {
                width: this.largura + 'px',
                height: this.altura + 'px'
            },
        }
    },
    methods: {
        fecharModal() {
            this.$emit('closeModal')
        },
        CriarKeydownEvent() {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.$emit('closeModal')
                }
            })
        }
    },
    created() {
        this.CriarKeydownEvent()
    }
}
</script>

<style lang='scss' scoped>
    .modal-header {
        background-color: #2e4283;
        padding: 0.5rem;
        Span {
            font-weight: bold;
            font-size: 16px !important;
            color: white;
        }

        .fechar {
            margin-top: 3px;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(16, 135, 247, 0.815);
            cursor: pointer;
            border-radius: 50%;
            width: 23px;
            height: 23px;
        }

        .fechar:hover {
            transform: scale(1.2)
        }
    }

    input {
        font-size: 0.8rem !important;
        text-align: center !important;
    }

    .modal-mask {
        position: fixed;
        z-index: 40;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        padding: 10px;
        font-size: 15px;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>