import axios from 'axios';
import config from 'config';

export const devolverPagamentoService = {
    enviar
}

function enviar(commit, dispatch, payload) {
    commit("exibirOcultarModalLoading", true)
    
    const requestOptions = {
        method: 'post',
        data: JSON.stringify(payload),
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('autheticationToken')}`,
            'Content-Type': 'application/json'
        }
    }
    return axios(`${config.api}/pagamento/devolucao/MERCADOPAGO`,requestOptions)
    .then(function(response) {
        return response
    })
    .catch(function (error) {
        dispatch('montarMensagemErro', error)
        return error
    })
    .finally (()=>{
        commit("exibirOcultarModalLoading", false)
    })
}