<template>
   <TemplateEditarLoja :dadosSelecao="dadosSelecao" :selecao="selecao" :funcaoEnviar = "enviarEdicao"/>
</template>

<script>
import TemplateEditarLoja from '@/components/frmNovaLojaEditar'

export default {
    components:{
        TemplateEditarLoja
    },

    props: {
        dadosSelecao: Object,
        selecao: Number
    },

    methods: {
        enviarEdicao(parametro) {
            let payload = {
                body: parametro.dadosLoja,
                url: parametro.inscricaoFederal,
                selecao: this.selecao
            }
            this.$store.dispatch('resgistrarLoja', payload)
        }
    },
}
</script>
